import React, { useEffect, useState } from "react"
import { useParams, useNavigate, useLocation } from "react-router-dom"

import moment from "moment"
import { Button, Table, Tooltip, Card, DatePicker } from "antd"

import { useDispatch, useSelector } from "react-redux"
import { getTreatmentPlans, getrpmCareplanDataActions } from "../../../redux/actions/rpmAction"
import { EyeOutlined } from "@ant-design/icons"
import { saveSelectedDateRange } from "../../../redux/actions/patientAction"
import MyDatePicker from "../../../utils/MyDatePicker"
import { USA_DATE_FORMAT } from "../../../utils/usaDateFormat"

const currentDate = new Date(moment(new Date())).toISOString()
const { RangePicker } = MyDatePicker

const setRows = arr => {
  const newData = []
  let sno = 1
  arr?.map(row => {
    const { id, planDate, plan } = row
    newData.push({
      planId: id,
      planDate,
      plan,
      key: sno,
      sNo: sno
    })
    sno += 1
    return id
  })
  return newData
}

const RPMPlanHistory = () => {
  const [RpmData, setRpmData] = useState([])

  const { id, orgId, orgName } = useParams()
  const navigate = useNavigate()

  const dispatch = useDispatch()
  const location = useLocation()

  const { loading: rpmLoading } = useSelector(state => state.rpmPatientCarePlan)
  const { loading } = useSelector(state => state.treatmentPlans)
  const selectedDateRange = useSelector(state => state.selectedDateRange)

  const [range, setRange] = useState(
    selectedDateRange?.RPMPlanHistoryRange
      ? [
          selectedDateRange?.RPMPlanHistoryRange?.startDate,
          selectedDateRange?.RPMPlanHistoryRange?.endDate
        ]
      : [moment(new Date()).subtract(1, "month").startOf("day"), moment(new Date()).endOf("day")]
  )
  // useEffect(() => {
  //   if (id) {
  //     dispatch(getrpmCareplanDataActions(id, new Date().getFullYear())).then(res => {
  //       setRpmData(setRows([...res]))
  //     })
  //   }
  // }, [dispatch])
  useEffect(() => {
    if (id) {
      dispatch(
        getTreatmentPlans(id, {
          startDate: moment(range[0]).format("YYYY-MM-DDTHH:mm:ss"),
          endDate: moment(range[1]).format("YYYY-MM-DDTHH:mm:ss")
        })
      ).then(res => {
        // Check if response is iterable
        if (Array.isArray(res)) {
          setRpmData(setRows([...res]))
        } else {
          console.error("Response is not iterable:", res)
        }
      })
    }
  }, [])
  const columns = [
    {
      title: "S.No.",
      dataIndex: "sNo",
      key: "sNo"
    },
    {
      title: "Plan Date",
      dataIndex: "planDate",
      key: "planDate",
      render: planDate => <p>{moment(planDate).format(USA_DATE_FORMAT)}</p>
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
      render: (_, { plan }) => (
        <p className="capitalize">
          {plan?.addingBy ? `${plan?.addingBy?.name} (${plan?.addingBy?.userType})` : ""}
        </p>
      )
    },
    {
      title: "Actions",
      render: (_, { planId }) => (
        <Tooltip title="View Plan">
          <Button
            type="primary"
            shape="circle"
            icon={<EyeOutlined />}
            onClick={() =>
              navigate(`/${orgId}/${orgName}/rpm-view-treatement/${id}`, {
                state: {
                  planId,
                  name: {
                    firstName: location?.state?.name?.firstName,
                    middleName: location?.state?.name?.middleName,
                    lastName: location?.state?.name?.lastName
                  },
                  dob: location?.state?.dob
                }
              })
            }
          />
        </Tooltip>
      )
    }
  ]

  // const handleRangeChange = (date, dateString) => {
  //   if (date && date?.length === 2) {
  //     // const startDate = date
  //     //   ? new Date(moment(new Date(dateString[0]))).toISOString()
  //     //   : new Date(moment(currentDate).subtract(1, "month")).toISOString()
  //     // const endDate = date ? new Date(moment(new Date(dateString[1]))).toISOString() : currentDate
  //     dispatch(
  //       getTreatmentPlans(id, {
  //         startDate: moment.utc(dateString[0]).startOf("day").toDate().toISOString(),
  //         endDate: moment.utc(dateString[1]).endOf("day").toDate().toISOString()
  //       })
  //     ).then(res => {
  //       setRpmData(setRows([...res]))
  //     })
  //   } else {
  //     dispatch(getrpmCareplanDataActions(id, new Date().getFullYear())).then(res => {
  //       setRpmData(setRows([...res]))
  //     })
  //   }
  // }

  // Function to disable dates outside one month range
  const disabledDate = current => {
    if (!range || range.length === 0) {
      // If no range is selected yet, allow all dates
      return false
    }
    return current > moment()
  }
  const handleDateReset = async () => {
    setRange([moment(new Date()).subtract(1, "month"), moment(new Date())])
    dispatch(
      saveSelectedDateRange(
        "RPMPlanHistoryRange",
        moment(new Date()).subtract(1, "month"),
        moment(new Date())
      )
    )
    dispatch(
      getTreatmentPlans(id, {
        startDate: moment.utc().subtract(1, "month").startOf("day").toDate().toISOString(),
        endDate: moment.utc().endOf("day").toDate().toISOString()
      })
    ).then(res => {
      setRpmData(setRows([...res]))
    })
  }
  const handleRangeChange = async (date, dateString) => {
    const startDate = moment(dateString[0], "MM-DD-YYYY", true);
    const endDate = moment(dateString[1], "MM-DD-YYYY", true);
    console.log(startDate, endDate)
    if (startDate.isValid() && endDate.isValid()) {
      setRange([startDate.startOf("day"), endDate.endOf("day")]);
      console.log(range)
    } else {
      console.error("Invalid date format");
    }
  }

  const handleDateSelection = async () => {
    if (range && range?.length === 2) {
      dispatch(saveSelectedDateRange("RPMPlanHistoryRange", range?.[0], range?.[1]))
      dispatch(
        getTreatmentPlans(id, {
          startDate: moment(range[0]).format("YYYY-MM-DDTHH:mm:ss"),
          endDate: moment(range[1]).format("YYYY-MM-DDTHH:mm:ss")
        })
      ).then(res => {
        setRpmData(setRows([...res]))
      })
    } else {
      dispatch(
        getTreatmentPlans(id, {
          startDate: moment.utc().subtract(1, "month").startOf("day").toDate().toISOString(),
          endDate: moment.utc().endOf("day").toDate().toISOString()
        })
      ).then(res => {
        setRpmData(setRows([...res]))
      })
    }
  }

  return (
    <div>
      <Card
        title={<h4 className="text-xl">Plan History</h4>}
        extra={
          <>
            <RangePicker
              value={range}
              allowClear={false}
              onChange={handleRangeChange}
              format={USA_DATE_FORMAT}
              disabledDate={disabledDate}
            />
            <Button
              type="primary"
              onClick={handleDateSelection}
              disabled={loading || rpmLoading}
              className="ml-2">
              Confirm
            </Button>
            <Button
              type="default"
              onClick={handleDateReset}
              disabled={loading || rpmLoading}
              className="ml-2 text-danger border-danger">
              Reset
            </Button>
          </>
        }>
        <Table
          columns={columns}
          dataSource={RpmData}
          scroll={{ x: "max-content" }}
          loading={loading || rpmLoading}
        />
        <p className="font-semibold">
          Use the Date picker in header to get plans in a specific date range.
        </p>
      </Card>
    </div>
  )
}

export default RPMPlanHistory
