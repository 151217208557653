import React from "react";
import { Button, Card, Divider, Avatar, Image } from "antd"
import { StarOutlined, FileOutlined, DownloadOutlined } from "@ant-design/icons"
import { IoBookmarkOutline } from "react-icons/io5"
import "./InternalNotes.css"

import { USA_DATE_FORMAT } from "../../utils/usaDateFormat"
import moment from "moment"

const getFormat = fileName => {
  const dotIdx = fileName?.lastIndexOf(".")
  if (dotIdx) {
    return fileName?.slice(dotIdx + 1)
  }
}

const isImage = fileName => {
  const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "svg", "webp"]
  const extension = fileName?.split(".")?.pop()?.toLowerCase()
  return imageExtensions?.includes(extension)
}

const downloadFile = async url => {
  const response = await fetch(url)
  const blob = await response.blob()
  const blobUrl = window.URL.createObjectURL(blob)
  const link = document.createElement("a")
  link.href = blobUrl
  link.setAttribute("download", url?.split("$")?.[1])
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
  window.URL.revokeObjectURL(blobUrl)
}

const ViewNotesCard = ({
  note,
  openIndivNote,
  handleViewNoteFunc,
  handleCloseNoteFunc,
  setEditNote
}) => {
  const displayWithLineBreaks = (text) => {
    return text.split("\n").map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  return (
    <div className="mb-4">
      <Card bodyStyle={{ padding: 10 }}>
        <div className="flex justify-between gap-2">
          <h4 className="text-xl mb-3">{note?.title}</h4>
          <div className="flex justify-center items-center gap-2">
            <Avatar
              size="small"
              className={`flex justify-center items-center border-[#d7a66d] ${note?.bookMark ? "bg-[#d7a66d]" : "bg-transparent text-current"
                }`}
              icon={<IoBookmarkOutline className="inline" />}
            />
            <Avatar
              size="small"
              className={`flex justify-center items-center border-[#d7a66d] ${note?.important ? "bg-[#d7a66d]" : "bg-transparent text-current"
                }`}
              icon={<StarOutlined className="inline" />}
            />
          </div>
        </div>

        <p className={`${openIndivNote ? "showFullText" : "showEllipsis"}`}>{displayWithLineBreaks(note?.concern)}</p>

        {note?.files?.length > 0 && (
          <div className="mt-3 flex flex-wrap gap-3">
            {note?.files.map((file, idx) => (
              <Card key={idx} bodyStyle={{ padding: "4px 10px", width: "240px" }}>
                <div className="flex justify-between items-center h-[40px]">
                  <div className="flex gap-2 items-center">
                    {isImage(file?.split("$")?.[1]) ? (
                      <Image width={32} src={file} />
                    ) : (
                      <Avatar shape="square" className="bg-current" icon={<FileOutlined />} />
                    )}
                    <div>
                      <p className="font-semibold mb-[-5px] truncate w-[140px]">
                        {file?.split("$")?.[1]}
                      </p>
                      <small className="uppercase text-secondary">
                        {getFormat(file?.split("$")?.[1])}
                      </small>
                    </div>
                  </div>
                  <Button
                    icon={<DownloadOutlined />}
                    size="small"
                    onClick={() => downloadFile(file)}
                  />
                </div>
              </Card>
            ))}
          </div>
        )}

        <Divider className="mb-1" />
        <div className="flex flex-col gap-3 items-start sm:flex-row justify-between sm:items-center">
          <div className="flex flex-col gap-0 sm:flex-row justify-center sm:items-center text-secondary">
            <span className="mr-8">
              <p>Created On : {moment(note.date).format(`${USA_DATE_FORMAT} [at] hh:mm A`)}{" "} {note?.timezone && `(${note?.timezone})`}</p>
            </span>
            <span>
              <p className="capitalize">Created by : {note?.createdByFirstName
                  ? note?.createdByFirstName +
                    " " +
                    (note?.createdByMiddleName ? note?.createdByMiddleName : "") +
                    " " +
                    note.createdByLastName
                  : "-"}
                </p>
            </span>
          </div>
          <div>
            {!openIndivNote ? (
              <Button
                type="default"
                className="border-[0px] text-[#d7a66d] text-sm font-medium"
                onClick={() => handleViewNoteFunc(note, true)}>
                View
              </Button>
            ) : (
              <Button
                type="default"
                className="border-[0px] text-[#d7a66d] text-sm font-medium"
                onClick={() => handleCloseNoteFunc(true)}>
                Back
              </Button>
            )}
            <Button
              type="default"
              className="border-[0px] text-[#d7a66d] text-sm font-medium"
              onClick={() => setEditNote(note)}>
              Edit
            </Button>
            {/* <Button type="default" className="border-[0px] text-[#d7a66d] text-sm font-medium">
              Delete
            </Button> */}
          </div>
        </div>
      </Card>
    </div>
  )
}

export default ViewNotesCard
