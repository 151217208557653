import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { Controller, useFieldArray, useForm } from "react-hook-form"
import { MinusCircleOutlined } from "@ant-design/icons"
import { addCriticalPatientAction } from "../../../redux/actions/rpmAction"
import { criticalPatientAction } from "../../../redux/actions/dashboardAction"
import { Button, Col, DatePicker, Input, Modal, Row, Select, Spin } from "antd"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import FloatInput from "../../../utils/FloatInput/FloatInput"
import moment from "moment"
import { getPatientMonitoringData, getTriageCriticalPatientsAction, resetPatientMonitoringData } from "../../../redux/actions/patientAction"
import { useToasts } from "react-toast-notifications"
import { ADD_CRITICAL_PATIENT_RESET } from "../../../redux/types/rpmConstants"
import {
  getPatientBpCustomParameter,
  getPatientGlucoCustomParameter,
  getPatientOxiCustomParameter
} from "../../../redux/actions/customParameterActions"

const defaultValues = {
    dynamicInputs: [
        {
            patientId: "",
            devices: {},
            issueDescription: "",
            readingDate: "",
        }
    ]
}

const BLOOD_PRESSURE_MACHINE = "blood pressure machine"
const OXIMETER = "oximeter"
const GLUCOSE_MACHINE = "glucose machine"
const WEIGHTH_MACHINE = "weight machine"
const ADD_READING_SUCCESS_MSG = "Critical readings added successfully"
const GENERIC_RANGE_ERROR_MSG = "Device critical level must be within the valid range"

// Hardcoded glucose ranges
const HARD_CODED_GLUC_RANGES = [
    { min: 49, max: 60 },
    { min: 140, max: 601 }
  ];
// Hardcoded SPO2 range
  const HARD_CODED_SPO2_RANGES = [
    { min: 0, max: 93 },
  ];
// Hardcoded bpm ranges
const HARD_CODED_BPM_RANGES = [
    { min: 10, max: 60 },
    { min: 100, max: 250 }
  ];
// Hardcoded sys ranges
const HARD_CODED_SYS_RANGES = [
    { min: 10, max: 90 },
    { min: 121, max: 300 }
  ];
// Hardcoded Dia ranges
const HARD_CODED_DIA_RANGES = [
    { min: 10, max: 60 },
    { min: 81, max: 150 }
  ];
  // Hardcoded bpm pulse ranges
const HARD_CODED_PULSE_BPM_RANGES = [
    { min: 10, max: 60 },
    { min: 100, max: 150 }
  ];

const AddCriticalPatient = ({ showAddCriticalpatient, setShowAddCriticalpatient }) => {
    const [deviceOptions, setDeviceOptions] = useState([])
    const [criticalPatientOptions, setCriticalPatientOptions] = useState([])
    const [patientId, setPatientId] = useState(null)
    const [glucoseRanges, setGlucoseRanges] = useState(HARD_CODED_GLUC_RANGES)
    const [spo2Ranges, setSpo2Ranges] = useState(HARD_CODED_SPO2_RANGES)
    const [bpmRanges, setBpmRanges] = useState(HARD_CODED_BPM_RANGES)
    const [sysRanges, setSysRanges] = useState(HARD_CODED_SYS_RANGES)
    const [diaRanges, setDiaRanges] = useState(HARD_CODED_DIA_RANGES)
    const [bpmPulseRanges, setPulseBpmRanges] = useState(HARD_CODED_PULSE_BPM_RANGES)

    const dispatch = useDispatch()
    const { orgId } = useParams()
    const { addToast } = useToasts()
    const { userInfo } = useSelector(state => state.userInfo)
    const { loading: criticalPatientLoading, criticalPatient } = useSelector(state => state.criticalPatient)
    const { patientMonitoringData, loading: monitoringLoading } = useSelector(state => state.patientMonitoringData)
    const {loading: addCriticalPostLoading, error: postError} = useSelector(state => state.addCriticalPatient)
    const { loading: GlucoCustomParameterLoading, glucoCustomParameters } = useSelector(state => state.getPatientGlucoCustomParameter)
    const { loading: OxiCustomParameterLoading, oxiCustomParameters } = useSelector(state => state.getPatientOxiCustomParameter)
    const { loading: BpCustomParameterLoading, bpCustomParameters } = useSelector(state => state.getPatientBpCustomParameter)

    const handleAddFields = () => {
        append({
            patientId: "",
            devices: {},
            issueDescription: "",
            readingDate: "",
        })
    }

    const schema = yup.object().shape({
      dynamicInputs: yup.array().of(
        yup.object().shape(
          {
            patientId: yup.string().required("Patient name is required"),
            devices: yup
              .object()
              .shape({
                value: yup
                  .string()
                  .transform(value => (value?.length > 0 ? value : null))
                  .required("Devices is required")
              })
              .required("Select an option"),
            systolicPressure: yup.number().when("devices", {
              is: value =>
                value?.label?.props?.children?.props?.children === BLOOD_PRESSURE_MACHINE,
              then: () =>
                yup
                  .number()
                  .transform(value => (Number.isNaN(value) ? null : value))
                  .test("is-in-range", function (value) {
                    const { diastolicPressure, heartRate } = this.parent

                    // Check diastolic pressure range
                    if (
                      diastolicPressure &&
                      diaRanges.some(
                        range => diastolicPressure >= range.min && diastolicPressure < range.max
                      )
                    ) {
                      if (value >= sysRanges[0].min && value < sysRanges[1].max) {
                        return true
                      }
                      return this.createError({
                        message: `${GENERIC_RANGE_ERROR_MSG} (${sysRanges[0].min}-${sysRanges[1].max})`
                      })
                    }

                    // Check heart rate range
                    if (
                      heartRate &&
                      bpmPulseRanges.some(range => heartRate > range.min && heartRate < range.max)
                    ) {
                      if (value >= sysRanges[0].min && value < sysRanges[1].max) {
                        return true
                      }
                      return this.createError({
                        message: `${GENERIC_RANGE_ERROR_MSG} (${sysRanges[0].min}-${sysRanges[1].max})`
                      })
                    }

                    // Default range check
                    if (!sysRanges.some(range => value >= range.min && value < range.max)) {
                      return this.createError({
                        message: `${GENERIC_RANGE_ERROR_MSG} (${sysRanges[0].min}-${sysRanges[0].max - 1}) or (${sysRanges[1].min}-${sysRanges[1].max}).`
                      })
                    }

                    return true
                  })
                  .required("Systolic Pressure is required"),
              otherwise: () => yup.number().optional()
            }),
            diastolicPressure: yup.number().when("devices", {
              is: value =>
                value?.label?.props?.children?.props?.children === BLOOD_PRESSURE_MACHINE,
              then: () =>
                yup
                  .number()
                  .transform(value => (Number.isNaN(value) ? null : value))
                  .test("is-in-range", function (value) {
                    const { systolicPressure, heartRate } = this.parent

                    // Check systolic pressure range
                    if (
                      systolicPressure &&
                      sysRanges.some(
                        range => systolicPressure >= range.min && systolicPressure < range.max
                      )
                    ) {
                      if (value >= diaRanges[0].min && value < diaRanges[1].max) {
                        return true
                      }
                      return this.createError({
                        message: `${GENERIC_RANGE_ERROR_MSG} (${diaRanges[0].min}-${diaRanges[1].max})`
                      })
                    }

                    // Check heart rate range
                    if (
                      heartRate &&
                      bpmPulseRanges.some(range => heartRate > range.min && heartRate < range.max)
                    ) {
                      if (value >= diaRanges[0].min && value < diaRanges[1].max) {
                        return true
                      }
                      return this.createError({
                        message: `${GENERIC_RANGE_ERROR_MSG} (${diaRanges[0].min}-${diaRanges[1].max})`
                      })
                    }

                    // Default range check
                    if (!diaRanges.some(range => value >= range.min && value < range.max)) {
                      return this.createError({
                        message: `${GENERIC_RANGE_ERROR_MSG} (${diaRanges[0].min}-${diaRanges[0].max - 1}) or (${diaRanges[1].min}-${diaRanges[1].max})`
                      })
                    }

                    return true
                  })
                  .required("Diastolic Pressure is required"),
              otherwise: () => yup.number().optional()
            }),
            heartRate: yup.number().when("devices", {
              is: value =>
                value?.label?.props?.children?.props?.children === BLOOD_PRESSURE_MACHINE,
              then: () =>
                yup
                  .number()
                  .transform(value => (Number.isNaN(value) ? null : value))
                  .test("is-in-range", function (value) {
                    const { systolicPressure, diastolicPressure } = this.parent

                    // Check systolic pressure range
                    if (
                      systolicPressure &&
                      sysRanges.some(
                        range => systolicPressure >= range.min && systolicPressure < range.max
                      )
                    ) {
                      if (value >= bpmPulseRanges[0].min && value < bpmPulseRanges[1].max) {
                        return true
                      }
                      return this.createError({
                        message: `${GENERIC_RANGE_ERROR_MSG} (${bpmPulseRanges[0].min}-${bpmPulseRanges[1].max})`
                      })
                    }

                    // Check diastolic pressure range
                    if (
                      diastolicPressure &&
                      diaRanges.some(
                        range => diastolicPressure >= range.min && diastolicPressure < range.max
                      )
                    ) {
                      if (value >= bpmPulseRanges[0].min && value < bpmPulseRanges[1].max) {
                        return true
                      }
                      return this.createError({
                        message: `${GENERIC_RANGE_ERROR_MSG} (${bpmPulseRanges[0].min}-${bpmPulseRanges[1].max})`
                      })
                    }

                    // Default range check
                    if (!bpmPulseRanges.some(range => value > range.min && value < range.max)) {
                      return this.createError({
                        message: `${GENERIC_RANGE_ERROR_MSG} (${bpmPulseRanges[0].min}-${bpmPulseRanges[0].max - 1}) or (${bpmPulseRanges[1].min + 1}-${bpmPulseRanges[1].max})`
                      })
                    }

                    return true
                  })
                  .required("Heart Rate is required"),
              otherwise: () => yup.number().optional()
            }),

            spo: yup.number().when("devices", {
              is: value => value?.label?.props?.children?.props?.children === OXIMETER,
              then: () =>
                yup
                  .number()
                  .transform(value => (Number.isNaN(value) ? null : value))
                  .test("is-in-range", function (value) {
                    const { pulseRate } = this.parent
                    // Check pulse rate range
                    if (
                      pulseRate &&
                      bpmRanges.some(range => pulseRate > range.min && pulseRate < range.max)
                    ) {
                      if (value >= spo2Ranges[0]?.min && value <= 100) {
                        return true
                      }
                      return this.createError({
                        message: `${GENERIC_RANGE_ERROR_MSG} (${spo2Ranges[0].min}-100)`
                      })
                    }

                    // Default range check
                    if (!spo2Ranges.some(range => value > range.min && value < range.max)) {
                      return this.createError({
                        message: `${GENERIC_RANGE_ERROR_MSG} (${spo2Ranges[0].min}-${spo2Ranges[0].max - 1})`
                      })
                    }

                    return true
                  })
                  .required("SpO2 is required"),
              otherwise: () => yup.number().optional()
            }),
            pulseRate: yup.number().when("devices", {
              is: value => value?.label?.props?.children?.props?.children === OXIMETER,
              then: () =>
                yup
                  .number()
                  .transform(value => (Number.isNaN(value) ? null : value))
                  .test("is-in-range", function (value) {
                    const { spo } = this.parent

                    // Check SpO2 range
                    if (spo && spo2Ranges.some(range => spo > range.min && spo < range.max)) {
                      if (value >= bpmRanges[0].min && value < bpmRanges[1].max) {
                        return true
                      }
                      return this.createError({
                        message: `${GENERIC_RANGE_ERROR_MSG} (${bpmRanges[0].min}-${bpmRanges[1].max})`
                      })
                    }

                    // Default range check
                    if (!bpmRanges.some(range => value > range.min && value < range.max)) {
                      return this.createError({
                        message: `${GENERIC_RANGE_ERROR_MSG} (${bpmRanges[0].min}-${bpmRanges[0].max - 1}) or (${bpmRanges[1].min + 1}-${bpmRanges[1].max})`
                      })
                    }

                    return true
                  })
                  .required("Pulse Rate is required"),
              otherwise: () => yup.number().optional()
            }),

            readingType: yup.string().when("devices", {
              is: value => value?.label?.props?.children?.props?.children === GLUCOSE_MACHINE,
              then: () => yup.string().required("ReadingType is required"),
              otherwise: () => yup.string().optional()
            }),
            glucoseLevel: yup.number().when("devices", {
              is: value => value?.label?.props?.children?.props?.children === GLUCOSE_MACHINE,
              then: () =>
                yup
                  .number()
                  .transform(value => (Number.isNaN(value) ? null : value))
                  .test(
                    "is-in-range",
                    `${GENERIC_RANGE_ERROR_MSG} (${glucoseRanges[0].min}-${glucoseRanges[0].max - 1}) or (${glucoseRanges[1].min + 1}-${glucoseRanges[1].max})`,
                    value => {
                      if (value == null) return true // Allow optional values

                      return glucoseRanges.some(range => value > range.min && value < range.max)
                    }
                  )
                  .required("Glucose Level is required"),
              otherwise: () => yup.number().optional()
            }),
            issueDescription: yup.string().required("Issue description is required"),
            readingDate: yup.string().required("Reading date is required")
          },
          ["systolicPressure", "diastolicPressure", "spo", "heartRate", "pulseRate"]
        )
      )
    })

const {
    handleSubmit,
    control,
    reset,
    watch,
    getValues,
    setValue,
    formState: { errors },
} = useForm({
    defaultValues,
    resolver: yupResolver(schema)
})

    const { fields, append, remove } = useFieldArray({ control, name: 'dynamicInputs' });
    const watchedFields = watch("dynamicInputs") || [];
console.log(watchedFields)
    useEffect(() => {
        if (patientId) {
            fields.forEach((_, index) => {
                setValue(`dynamicInputs.${index}.patientId`, patientId);
            });
        }
    }, [patientId, fields, setValue]);

      useEffect(() => {
        // Fetch specific endpoints based on device options
        const fetchDeviceData = async () => {
          for (const ele of deviceOptions) {
            const deviceName = ele.label.props.children.props.children.toLowerCase();
    
            if (deviceName === BLOOD_PRESSURE_MACHINE) {
                dispatch(getPatientBpCustomParameter(patientId))
            } else if (deviceName === OXIMETER) {
                dispatch(getPatientOxiCustomParameter(patientId))
            } else if (deviceName === GLUCOSE_MACHINE) {
                dispatch(getPatientGlucoCustomParameter(patientId))
            }
          }
        };
    
        if (deviceOptions.length > 0) {
          fetchDeviceData();
        }
      }, [deviceOptions]);

  // Effect to set glucose ranges from Redux state
  useEffect(() => {
    if (glucoCustomParameters) {
      const dynamicRanges = [
        { min: glucoCustomParameters.lowerLimit, max: glucoCustomParameters.normalLower }, // Lower range
        { min: glucoCustomParameters.normalHigher, max: glucoCustomParameters.higherLimit } // Higher range
      ];
      setGlucoseRanges(dynamicRanges);
    } else {
      // Fallback to hardcoded ranges if glucoCustomParameters is not available
      setGlucoseRanges(HARD_CODED_GLUC_RANGES);
    }
  }, [glucoCustomParameters]);

    // Effect to set oximeter ranges from Redux state
    useEffect(() => {
        if (oxiCustomParameters) {
          const dynamicRangesSpo2 = [
            { min: oxiCustomParameters.lowerLimitSPO, max: oxiCustomParameters.normalLowSPO }
          ];
          const dynamicRangesBpm = [
            { min: oxiCustomParameters.lowerPulseRate, max: oxiCustomParameters.normalLowBPM }, // Lower range
            { min: oxiCustomParameters.normalHighBPM, max: oxiCustomParameters.higherPulseRate } // Higher range
          ]
          setSpo2Ranges(dynamicRangesSpo2);
          setBpmRanges(dynamicRangesBpm)
        } else {
          // Fallback to hardcoded ranges if oxiCustomParameters is not available
          setSpo2Ranges(HARD_CODED_SPO2_RANGES);
          setBpmRanges(HARD_CODED_BPM_RANGES)
        }
      }, [oxiCustomParameters]);

    // Effect to set BP ranges from Redux state
    useEffect(() => {
        if (bpCustomParameters) {
          const dynamicRangesSys = [
            { min: bpCustomParameters.lowerLimitSystolic, max: bpCustomParameters.normalSystolic }, // Lower range
            { min: bpCustomParameters.preHyperTensionSystolic, max: bpCustomParameters.higherLimitSystolic } // Higher range
          ];
          const dynamicRangesDia = [
            { min: bpCustomParameters.lowerLimitDiastolic, max: bpCustomParameters.normalDiastolic }, // Lower range
            { min: bpCustomParameters.preHyperTensionDiastolic, max: bpCustomParameters.higherLimitDiastolic } // Higher range
          ]
          const dynamicRangesPulseBpm = [
            { min: bpCustomParameters.bpmLow, max: bpCustomParameters.bpmNormalLow }, // Lower range
            { min: bpCustomParameters.bpmNormalHigh, max: bpCustomParameters.bpmHigh } // Higher range
          ]
          setSysRanges(dynamicRangesSys)
          setDiaRanges(dynamicRangesDia)
          setPulseBpmRanges(dynamicRangesPulseBpm)
        } else {
          // Fallback to hardcoded ranges if bpCustomParameters is not available
          setSysRanges(HARD_CODED_SYS_RANGES)
          setDiaRanges(HARD_CODED_DIA_RANGES)
          setPulseBpmRanges(HARD_CODED_PULSE_BPM_RANGES)
        }
      }, [bpCustomParameters]);


    useEffect(() => {
        dispatch(resetPatientMonitoringData())
        criticalPatient === undefined && !criticalPatient?.length && dispatch(criticalPatientAction(orgId))
    }, [showAddCriticalpatient])

    useEffect(() => {
        if (patientId) dispatch(getPatientMonitoringData({ patientId }))
    }, [patientId])

    useEffect(() => {
        let newDeviceOptions = []
        patientMonitoringData?.rpm?.forEach(item => {
            item?.programs?.forEach(ele => {
                if (ele?.programName !== WEIGHTH_MACHINE) {
                    newDeviceOptions.push({
                        value: ele?.programId,
                        label: <><span className="capitalize">{ele?.programName}</span></>
                    })
                }
            })
        })
        setDeviceOptions(newDeviceOptions)
    }, [patientMonitoringData])

    useEffect(() => {
        if (criticalPatient && criticalPatient?.length > 0) {
            let newCriticalPatientOption = []
            criticalPatient?.map(item => {
                newCriticalPatientOption.push({
                    value: item?.id,
                    label: <><span className="capitalize">{`${item?.firstName}${item?.middleName ? " " + item.middleName : ""} ${item?.lastName}`}</span></>
                })
            })
            setCriticalPatientOptions(newCriticalPatientOption)
        }
    }, [criticalPatient])

    const isSubmitDisabled = watchedFields.some((input) => {
      const deviceLabel = input.devices?.label?.props?.children?.props?.children;
      const isDeviceValueMissing = !input.devices?.value;
      const isPatientIdMissing = !input.patientId;

      // Conditions based on device type
      if (deviceLabel === OXIMETER) {
          return isDeviceValueMissing || isPatientIdMissing || input.spo === undefined || input.pulseRate === undefined;
      }
      if (deviceLabel === BLOOD_PRESSURE_MACHINE) {
          return isDeviceValueMissing || isPatientIdMissing || !input.systolicPressure || !input.diastolicPressure || !input.heartRate;
      }
      if (deviceLabel === GLUCOSE_MACHINE) {
          return isDeviceValueMissing || isPatientIdMissing || input.glucoseLevel === undefined || !input?.readingType;
      }
      
      // General condition for all other cases
      return isDeviceValueMissing || isPatientIdMissing;
  });

    const onSubmit = async (data) => {
        let patientId = null
        let bpMonitorId = null
        let glucoseMonitorId = null
        let oximeterMonitorId = null
        let BpMachineReadings = []
        let OximeterReadings = []
        let GlucoseMachineReadings = []
        data?.dynamicInputs?.forEach(ele => {
            if (ele?.devices?.label?.props?.children?.props?.children === BLOOD_PRESSURE_MACHINE) {
                bpMonitorId = ele?.devices?.value
                patientId = ele?.patientId
                const bpReading = {
                    systolicPressure: ele?.systolicPressure,
                    diastolicPressure: ele?.diastolicPressure,
                    heartRate: ele?.heartRate,
                    pulseUnit: "bpm",
                    issueDescription: ele?.issueDescription?.trim(),
                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    bloodPressureUnit: "mmHg",
                    readingDate: moment(ele?.readingDate).format('YYYY-MM-DDTHH:mm:ss.SSS')
                }
                BpMachineReadings.push(bpReading)
            } else if (ele?.devices?.label?.props?.children?.props?.children === OXIMETER) {
                oximeterMonitorId = ele?.devices?.value
                patientId = ele?.patientId
                const oxiReading = {
                    pulseRate: ele?.pulseRate,
                    spo: ele?.spo,
                    pulseUnit: "bpm",
                    issueDescription: ele?.issueDescription?.trim(),
                    readingDate: moment(ele?.readingDate).format('YYYY-MM-DDTHH:mm:ss.SSS'),
                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
                }
                OximeterReadings.push(oxiReading)
            } else if (ele?.devices?.label?.props?.children?.props?.children === GLUCOSE_MACHINE) {
                glucoseMonitorId = ele?.devices?.value
                patientId = ele?.patientId
                const glucoseReading = {
                    glucoseLevel: ele?.glucoseLevel,
                    glucoseUnit: "mg/dl",
                    issueDescription: ele?.issueDescription?.trim(),
                    readingDate: moment(ele?.readingDate).format('YYYY-MM-DDTHH:mm:ss.SSS'),
                    readingType: ele?.readingType,
                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
                }
                GlucoseMachineReadings.push(glucoseReading)
            }
        });

        const formData = {
            bpMonitorId,
            glucoseMonitorId,
            oximeterMonitorId,
            registerBPMachineReadingsListDTO: BpMachineReadings,
            registerGlucoseMeterReadingsListDTO: GlucoseMachineReadings,
            registerOximeterReadingsListDTO: OximeterReadings
        }
        const result = await dispatch(addCriticalPatientAction(patientId, formData, userInfo?.id));
        if (result?.status === 200) {
            addToast(ADD_READING_SUCCESS_MSG, {
                appearance: "success",
                autoDismiss: true
            })
            dispatch(getTriageCriticalPatientsAction(orgId))
            setShowAddCriticalpatient(false)
        } 
    }

    const handleCancel = () => {
        dispatch({type:ADD_CRITICAL_PATIENT_RESET})
        setShowAddCriticalpatient(false)
    }
    const currentMoment = moment();

    const disabledTime = current => {
        if (!current) {
            return {};
        }

        const isSameDay = current.isSame(currentMoment, 'day');

        const disabledHours = () => {
            if (isSameDay) {
                return [...Array(24).keys()].filter(hour => hour > currentMoment.hour());
            }
            return [];
        };

        const disabledMinutes = () => {
            if (isSameDay && current.hour() === currentMoment.hour()) {
                return [...Array(60).keys()].filter(minute => minute > currentMoment.minute());
            }
            return [];
        };

        return {
            disabledHours,
            disabledMinutes,
        };
    };

    return (
        <Modal
            open={showAddCriticalpatient}
            width={800}
            footer={null}
            onOk={handleCancel}
            onCancel={handleCancel}
            modalRender={modal => {
                return React.cloneElement(modal, {
                  style: {
                    ...modal.props.style,
                    ...{ marginTop: "-55px" }
                  }
                })
              }}
        >
            <form className="pt-4" onSubmit={handleSubmit(onSubmit)}>
                <Spin spinning={false}>
                    {fields.map((item, index) => (
                        <div key={item.id}>
                            <Row gutter={16}>
                                <Col className="mb-4" xs={12} md={12} lg={7}>
                                    <label className="mb-1" htmlFor="input-patientName">
                                        Patient Name{" "}
                                        <span className="text-danger">*</span>
                                    </label>
                                    <Controller
                                        name={`dynamicInputs.${index}.patientId`}
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                            <Select
                                                showSearch={true}
                                                size="large"
                                                className="w-100"
                                                placeholder="Select Patient"
                                                notFoundContent={criticalPatientLoading ? <Spin size="small" /> : null}
                                                filterOption={(input, option) => option?.label?.props?.children?.props?.children?.toLowerCase().includes(input.toLowerCase())}
                                                status={errors.dynamicInputs?.[index]?.patientId ? "error" : undefined}
                                                options={criticalPatientOptions}
                                                disabled={index > 0 ? (patientId ? true : false) : false}
                                                value={patientId ? patientId : undefined}
                                                onChange={(value) => {
                                                    field.onChange(value)
                                                    setPatientId(value)
                                                    // reset device nd remaing fields
                                                    setValue(`dynamicInputs.${index}.devices`, {})
                                                    setDeviceOptions([])
                                                }}
                                                onKeyDown={(e) => {
                                                  // Prevent space
                                                  if (e.key === ' ' && (e.target.selectionStart === 0 || e.target.value[e.target.selectionStart - 1] === ' ')) {
                                                      e.preventDefault();
                                                  }
                                              }}
                                            />
                                        )}
                                    />
                                    {errors.dynamicInputs?.[index]?.patientId ? (
                                        <small className="text-danger capitalize">{errors.dynamicInputs[index].patientId.message}</small>
                                    ) : null}
                                </Col>
                                <Col className="mb-4" xs={12} md={12} lg={7}>
                                    <label className="mb-1" htmlFor="input-device">
                                        Devices{" "}
                                        <span className="text-danger">*</span>
                                    </label>
                                   { monitoringLoading ? <Spin size="small" className="mt-8"/> :<Controller
                                        name={`dynamicInputs.${index}.devices`}
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                            <Select
                                                size="large"
                                                className="w-full"
                                                labelInValue={true}
                                                placeholder="Select Device"
                                                notFoundContent={monitoringLoading ? <Spin size="small" /> : "No Device Found"}
                                                status={errors.dynamicInputs?.[index]?.devices?.value ? "error" : undefined}
                                                options={deviceOptions}
                                                onChange={(value) => {
                                                    field.onChange(value)
                                                }}
                                            />
                                        )}
                                    />}
                                    {errors.dynamicInputs?.[index]?.devices ? (
                                        <small className="text-danger capitalize">{errors.dynamicInputs[index].devices?.value.message}</small>
                                    ) : null}
                                </Col>
                                {watchedFields[index]?.devices?.label?.props?.children?.props?.children === BLOOD_PRESSURE_MACHINE && (
                                    <>
                                        <Col className="mb-4" xs={8} md={4} lg={4}>
                                            <label className="mb-1" htmlFor="input-critical-value">
                                                Critical Value{" "}
                                                <span className="text-danger">*</span>
                                            </label>
                                            <Controller
                                                name={`dynamicInputs.${index}.systolicPressure`}
                                                control={control}
                                                rules={{ required: true }}
                                                render={({ field }) => (
                                                    <FloatInput label="SYS" placeholder="SYS">
                                                        <Input
                                                            size="large"
                                                            status={errors.dynamicInputs?.[index]?.systolicPressure ? "error" : undefined}
                                                            {...field}
                                                            onChange={(e) => {
                                                                const value = e.target.value.replace(/[^\d]/g, ''); // Keep only digits
                                                                field.onChange(value); // Update with the filtered value
                                                            }}
                                                        />
                                                    </FloatInput>
                                                )}
                                            />
                                            {errors.dynamicInputs?.[index]?.systolicPressure ? (
                                                <small className="text-danger capitalize">{errors.dynamicInputs[index].systolicPressure.message}</small>
                                            ) : null}
                                        </Col>
                                        <Col className="mb-4 pt-[26px]" xs={8} md={4} lg={3}>
                                            <Controller
                                                name={`dynamicInputs.${index}.diastolicPressure`}
                                                control={control}
                                                rules={{ required: true }}
                                                render={({ field }) => (
                                                    <FloatInput label="DIA" placeholder="DIA">
                                                        <Input
                                                            size="large"
                                                            status={errors.dynamicInputs?.[index]?.diastolicPressure ? "error" : undefined}
                                                            {...field}
                                                            onChange={(e) => {
                                                                const value = e.target.value.replace(/[^\d]/g, ''); // Keep only digits
                                                                field.onChange(value); // Update with the filtered value
                                                            }}
                                                        />
                                                    </FloatInput>
                                                )}
                                            />
                                            {errors.dynamicInputs?.[index]?.diastolicPressure ? (
                                                <small className="text-danger capitalize">{errors.dynamicInputs[index].diastolicPressure.message}</small>
                                            ) : null}
                                        </Col>
                                        <Col className="mb-4 pt-[26px]" xs={8} md={4} lg={3}>
                                            <Controller
                                                name={`dynamicInputs.${index}.heartRate`}
                                                control={control}
                                                rules={{ required: true }}
                                                render={({ field }) => (
                                                    <FloatInput label="BPM" placeholder="BPM">
                                                        <Input
                                                            size="large"
                                                            status={errors.dynamicInputs?.[index]?.heartRate ? "error" : undefined}
                                                            {...field}
                                                            onChange={(e) => {
                                                                const value = e.target.value.replace(/[^\d]/g, ''); // Keep only digits
                                                                field.onChange(value); // Update with the filtered value
                                                            }}
                                                        />
                                                    </FloatInput>
                                                )}
                                            />
                                            {errors.dynamicInputs?.[index]?.heartRate ? (
                                                <small className="text-danger capitalize">{errors.dynamicInputs[index].heartRate.message}</small>
                                            ) : null}
                                        </Col>
                                    </>
                                )}
                                {watchedFields[index]?.devices?.label?.props?.children?.props?.children === OXIMETER && (
                                    <>
                                        <Col className="mb-4" xs={8} md={4} lg={4}>
                                            <label className="mb-1" htmlFor="input-critical-value">
                                                Critical Value{" "}
                                                <span className="text-danger">*</span>
                                            </label>
                                            <Controller
                                                name={`dynamicInputs.${index}.spo`}
                                                control={control}
                                                rules={{ required: true }}
                                                render={({ field }) => (
                                                    <FloatInput label="SpO2" placeholder="SpO2">
                                                        <Input
                                                            size="large"
                                                            status={errors.dynamicInputs?.[index]?.spo ? "error" : undefined}
                                                            {...field}
                                                            onChange={(e) => {
                                                                const value = e.target.value.replace(/[^\d]/g, ''); // Keep only digits
                                                                field.onChange(value); // Update with the filtered value
                                                            }}
                                                        />
                                                    </FloatInput>
                                                )}
                                            />
                                            {errors.dynamicInputs?.[index]?.spo ? (
                                                <small className="text-danger capitalize">{errors.dynamicInputs[index].spo.message}</small>
                                            ) : null}
                                        </Col>
                                        <Col className="mb-4 pt-[26px]" xs={8} md={4} lg={4}>
                                            <Controller
                                                name={`dynamicInputs.${index}.pulseRate`}
                                                control={control}
                                                rules={{ required: true }}
                                                render={({ field }) => (
                                                    <FloatInput label="BPM" placeholder="BPM">
                                                        <Input
                                                            size="large"
                                                            status={errors.dynamicInputs?.[index]?.pulseRate ? "error" : undefined}
                                                            {...field}
                                                            onChange={(e) => {
                                                                const value = e.target.value.replace(/[^\d]/g, ''); // Keep only digits
                                                                field.onChange(value); // Update with the filtered value
                                                            }}
                                                        />
                                                    </FloatInput>
                                                )}
                                            />
                                            {errors.dynamicInputs?.[index]?.pulseRate ? (
                                                <small className="text-danger capitalize">{errors.dynamicInputs[index].pulseRate.message}</small>
                                            ) : null}
                                        </Col>
                                    </>
                                )}
                                {watchedFields[index]?.devices?.label?.props?.children?.props?.children === GLUCOSE_MACHINE && (
                                    <>
                                        <Col className="mb-4" xs={8} md={4} lg={5}>
                                            <label className="mb-1" htmlFor="input-critical-value">
                                                Critical Value{" "}
                                                <span className="text-danger">*</span>
                                            </label>
                                            <Controller
                                                name={`dynamicInputs.${index}.readingType`}
                                                control={control}
                                                rules={{ required: true }}
                                                render={({ field }) => (
                                                    <FloatInput label="ReadingType" placeholder="Reading Type">
                                                        <Select
                                                            size="large"
                                                            className="w-100"
                                                            status={errors.dynamicInputs?.[index]?.readingType ? "error" : undefined}
                                                            options={[
                                                                {
                                                                    value: "after",
                                                                    label: "After Meal"
                                                                },
                                                                {
                                                                    value: "before",
                                                                    label: "Before Meal"
                                                                }
                                                            ]}
                                                            {...field}
                                                        />
                                                    </FloatInput>
                                                )}
                                            />
                                            {errors.dynamicInputs?.[index]?.readingType ? (
                                                <small className="text-danger capitalize">{errors.dynamicInputs[index].readingType.message}</small>
                                            ) : null}
                                        </Col>
                                        <Col className="mb-4 pt-[26px]" xs={8} md={4} lg={4}>
                                            <Controller
                                                name={`dynamicInputs.${index}.glucoseLevel`}
                                                control={control}
                                                rules={{ required: true }}
                                                render={({ field }) => (
                                                    <FloatInput label="mg/dl" placeholder="mg/dl">
                                                        <Input
                                                            size="large"
                                                            status={errors.dynamicInputs?.[index]?.readingType ? "error" : undefined}
                                                            {...field}
                                                            onChange={(e) => {
                                                                const value = e.target.value.replace(/[^\d]/g, ''); // Keep only digits
                                                                field.onChange(value); // Update with the filtered value
                                                            }}
                                                        />
                                                    </FloatInput>
                                                )}
                                            />
                                            {errors.dynamicInputs?.[index]?.glucoseLevel ? (
                                                <small className="text-danger capitalize">{errors.dynamicInputs[index].glucoseLevel.message}</small>
                                            ) : null}
                                        </Col>
                                    </>
                                )}
                            </Row>
                            <Row gutter={16}>
                                <Col className="mb-4" xs={24} md={24} lg={16}>
                                    <label className="mb-1" htmlFor="input-description">
                                        Describe the Issue{" "}
                                        <span className="text-danger">*</span>
                                    </label>
                                    <Controller
                                        name={`dynamicInputs.${index}.issueDescription`}
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                            <Input.TextArea
                                                size="large"
                                                Rows={3}
                                                cols={5}
                                                className="w-100"
                                                placeholder="Describe the issue"
                                                status={errors.dynamicInputs?.[index]?.issueDescription ? "error" : undefined}
                                                {...field}
                                                // onChange={e => field.onChange(e.target.value?.replace(/^\s+/g, "")?.replace(/\s{2,}/g, " "))}
                                            />
                                        )}
                                    />
                                    {errors.dynamicInputs?.[index]?.issueDescription ? (
                                        <small className="text-danger capitalize">{errors.dynamicInputs[index].issueDescription.message}</small>
                                    ) : null}
                                </Col>
                                <Col className="mb-4 mt-[26px] flex flex-col items-start gap-[6px]" xs={8} md={8} lg={8}>
                                    <div className=" h-14 flex flex-col justify-start items-start">
                                        <Controller
                                            name={`dynamicInputs.${index}.readingDate`}
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field }) => (
                                                <DatePicker
                                                    showTime
                                                    format="MM-DD-YYYY HH:mm:ss"
                                                    disabledDate={current => current && current > moment().endOf("day")}
                                                    disabledTime={disabledTime}
                                                    size="large"
                                                    status={errors.dynamicInputs?.[index]?.readingDate ? "error" : undefined}
                                                    {...field}
                                                />
                                            )}
                                        />
                                        {errors.dynamicInputs?.[index]?.readingDate ? (
                                            <small className="text-danger capitalize">{errors.dynamicInputs[index].readingDate.message}</small>
                                        ) : null}
                                    </div>
                                    {fields?.length > 1 && (
                                        <Button
                                            className="bg-danger flex justify-center items-center text-center"
                                            onClick={() => remove(index)}>
                                            <MinusCircleOutlined className="text-[#fff]" style={{ margin: 0 }} />
                                        </Button>
                                    )}
                                </Col>
                            </Row>
                        </div>
                    ))}
                    {postError ? (
                      <div className="my-2">
                        <p className="text-danger font-semibold">{postError}</p>
                      </div>
                    ) : null}
                    <Row gutter={16}>
                        <Col className="mb-4 pt-4">
                            <Button type="primary" size="large" htmlType="submit" disabled={isSubmitDisabled} loading={addCriticalPostLoading}>
                                Save
                            </Button>
                        </Col>
                        <Col xs={12} className="mb-4 pt-4">
                            <Button type="primary" size="large" onClick={handleAddFields} disabled={addCriticalPostLoading}>
                                Add More Critical Reading
                            </Button>
                        </Col>
                    </Row>
                </Spin>
            </form>
        </Modal>
    )
}

export default AddCriticalPatient