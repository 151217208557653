import React, { useEffect, useState } from "react"
import { useParams, useNavigate } from "react-router-dom"
import moment from "moment"
import { Button, Table, Tooltip, Card, DatePicker } from "antd"
import { useDispatch, useSelector } from "react-redux"
import {
    getccmCareplanDataActions,
    getCcmArchivePlansActions
} from "../../../redux/actions/ccmActions"
import { EyeOutlined } from "@ant-design/icons"
import { saveSelectedDateRange } from "../../../redux/actions/patientAction"
import MyDatePicker from "../../../utils/MyDatePicker"
import { getPcmArchivePlansActions } from "../../../redux/actions/pcmActions"

const { RangePicker } = MyDatePicker

const DATEFORMAT = "YYYY-MM-DDTHH:mm:ss"

const setRows = arr => {
    const newData = []
    let sno = 1
    arr?.map(row => {
        const { id, planDate, plan } = row
        newData.push({
            planId: id,
            planDate,
            plan,
            key: sno,
            sNo: sno
        })
        sno += 1
        return id
    })
    return newData
}

const PcmPlanHistoryList = () => {
    const [ccmData, setCcmData] = useState([])

    const { id, orgId, orgName } = useParams()
    const navigate = useNavigate()

    const dispatch = useDispatch()
    const { loading: ccmLoading } = useSelector(state => state.ccmPatientCarePlan)
    const { loading: ccmArchiveLoading } = useSelector(state => state.ccmArchivePlans)

    const selectedDateRange = useSelector(state => state.selectedDateRange)

    const [range, setRange] = useState(
        selectedDateRange?.CCMPlanHistoryRange
            ? [
                selectedDateRange?.CCMPlanHistoryRange?.startDate,
                selectedDateRange?.CCMPlanHistoryRange?.endDate
            ]
            : [moment(new Date()).subtract(1, "month").startOf("day"), moment(new Date()).endOf("day")]
    )
    // useEffect(() => {
    //   if (id) {
    //     dispatch(getccmCareplanDataActions({ patientId: id })).then(({ details }) => {
    //       setCcmData(setRows(details))
    //     })
    //   }
    // }, [dispatch, id])
    useEffect(() => {
        if (id) {
            dispatch(
                getPcmArchivePlansActions({
                    patientId: id,
                    startDate: moment(range[0]).format(DATEFORMAT),
                    endDate: moment(range[1]).format(DATEFORMAT)
                })
            ).then(res => {
                // Check if response is iterable
                if (Array.isArray(res)) {
                    setCcmData(setRows([...res]))
                } else {
                    console.error("Response is not iterable:", res)
                }
            })
        }
    }, [])

    const columns = [
        {
            title: "S.No.",
            dataIndex: "sNo",
            key: "sNo"
        },
        {
            title: "Plan Date",
            dataIndex: "planDate",
            key: "planDate",
            render: planDate => <p>{moment(planDate).format("MM-DD-YYYY")}</p>,
            sorter: (a, b) => a.planDate.localeCompare(b.planDate)
        },
        {
            title: "Created By",
            dataIndex: "createdBy",
            key: "createdBy",
            render: (_, { plan }) => (
                <p className="capitalize">
                    {plan?.addedBy ? `${plan?.addedBy?.name} (${plan?.addedBy?.userType})` : ""}
                </p>
            )
        },
        {
            title: "Actions",
            render: (_, { planId, planDate, plan }) => (
                <Tooltip title="View Plan">
                    <Button
                        type="primary"
                        shape="circle"
                        icon={<EyeOutlined />}
                        onClick={() =>
                            navigate(`/${orgId}/${orgName}/pcm-history-view-plan`, { state: { patientId: id, planDate, plan } })
                        }
                    />
                </Tooltip>
            )
        }
    ]

    // const handleRangeChange = (date, dateString) => {
    //   if (date && date?.length === 2) {
    //     dispatch(
    //       getCcmArchivePlansActions({
    //         patientId: id,
    //         startDate: moment.utc(dateString[0]).startOf("day").toDate().toISOString(),
    //         endDate: moment.utc(dateString[1]).endOf("day").toDate().toISOString()
    //       })
    //     ).then(res => {
    //       setCcmData(setRows([...res]))
    //     })
    //   } else {
    //     dispatch(getccmCareplanDataActions({ patientId: id })).then(({ details }) => {
    //       setCcmData(setRows([...details]))
    //     })
    //   }
    // }
    // Function to disable dates outside one month range
    const disabledDate = current => {
        if (!range || range.length === 0) {
            // If no range is selected yet, allow all dates
            return false
        }
        return current > moment()
    }
    const handleDateReset = async () => {
        setRange([moment(new Date()).subtract(1, "month"), moment(new Date())])
        dispatch(
            saveSelectedDateRange(
                "CCMPlanHistoryRange",
                moment(new Date()).subtract(1, "month"),
                moment(new Date())
            )
        )
        dispatch(
            getPcmArchivePlansActions({
                patientId: id,
                startDate: moment.utc().subtract(1, "month").startOf("day").toDate().toISOString(),
                endDate: moment.utc().endOf("day").toDate().toISOString()
            })
        ).then(res => {
            setCcmData(setRows([...res]))
        })
    }
    const handleRangeChange = async (date, dateString) => {
        const startDate = moment(dateString[0], "MM-DD-YYYY", true);
        const endDate = moment(dateString[1], "MM-DD-YYYY", true);
        console.log(startDate, endDate)
        if (startDate.isValid() && endDate.isValid()) {
          setRange([startDate.startOf("day"), endDate.endOf("day")]);
          console.log(range)
        } else {
          console.error("Invalid date format");
        }
      }
    const handleDateSelection = async () => {
        if (range && range?.length === 2) {
            dispatch(saveSelectedDateRange("CCMPlanHistoryRange", range?.[0], range?.[1]))

            dispatch(
                getPcmArchivePlansActions({
                    patientId: id,
                    startDate: moment(range[0]).format(DATEFORMAT),
                    endDate: moment(range[1]).format(DATEFORMAT)
                })
            ).then(res => {
                setCcmData(setRows([...res]))
            })
        } else {
            dispatch(
                getPcmArchivePlansActions({
                    patientId: id,
                    startDate: moment.utc().subtract(1, "month").startOf("day").toDate().toISOString(),
                    endDate: moment.utc().endOf("day").toDate().toISOString()
                })
            ).then(res => {
                setCcmData(setRows([...res]))
            })
        }
    }
    return (
        <div>
            <Card
                title={<h4 className="text-xl">Plan History</h4>}
                extra={
                    <>
                        <RangePicker
                            value={range}
                            onChange={handleRangeChange}
                            allowClear={false}
                            format="MM-DD-YYYY"
                            disabledDate={disabledDate}
                        />
                        <Button
                            type="primary"
                            onClick={handleDateSelection}
                            disabled={ccmArchiveLoading || ccmLoading}
                            className="ml-2">
                            Confirm
                        </Button>
                        <Button
                            type="default"
                            onClick={handleDateReset}
                            disabled={ccmArchiveLoading || ccmLoading}
                            className="ml-2 text-danger border-danger">
                            Reset
                        </Button>
                    </>
                }>
                <Table
                    size="small"
                    columns={columns}
                    dataSource={ccmData}
                    scroll={{ x: "max-content" }}
                    loading={ccmArchiveLoading || ccmLoading}
                />
                <p className="font-semibold">
                    Use the Date picker in header to get plans in a specific date range.
                </p>
            </Card>
        </div>
    )
}

export default PcmPlanHistoryList
