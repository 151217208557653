import React, { useEffect, useState } from "react"

import { useToasts } from "react-toast-notifications"
import { Modal, Select, Row, Col, Button } from "antd"

import { useDispatch, useSelector } from "react-redux"
import {
  getPatientInfo,
  getFacilityPatientAddressAction,
  updateFacilityPatientAddressAction,
  postFacilityPatientAddressAction,
  getAdvancedFiltersOptions,
  getPatientMenuConfigAction,
  getFacilityUserAddressReset,
  getfacilityUserAddress
} from "../../../redux/actions/patientAction"

import * as yup from "yup"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { useLocation, useParams } from "react-router-dom"
import Loading from "../../layouts/Loading/Loading"
import { RESET_POST_PATIENT_FACILITY_ADDRESS } from "../../../redux/types/PatientConstant"
import { customUnitRoomSort } from "../../../utils/customUnitRoomSort"
import { bedPositionWOBed } from "../../../utils/bedPositionWOBed"

const getUnits = (arr, facilityId) => arr?.find(i => i.facilityId === facilityId)

const getRooms = (arr, facilityId, facilityUnit) =>
  arr
    ?.find(i => i.facilityId === facilityId)
    ?.filterOptions?.find(r => r.units.unit === facilityUnit)?.units?.rooms

const getBedPositions = (arr, facilityId, facilityUnit) =>
  arr
    ?.find(i => i.facilityId === facilityId)
    ?.filterOptions?.find(r => r.units.unit === facilityUnit)?.units?.bedPositions

const schema = yup
  .object({
    room: yup.string().required("Invalid Room Number"),
    unit: yup.string().required("Invalid Unit Number"),
    bedPosition: yup.string().required("Invalid Bed Position"),
    facility: yup.string().required("Invalid Facility")
  })
  .required()

const UpdatePatientFacility = props => {
  const { updateFacilityShow, handleUpdateFacilityClose } = props

  const dispatch = useDispatch()
  const location = useLocation()
  const {orgId} = useParams()

  const { patientInfoDetail } = useSelector(state => state.patientInfo)
  const { loading : patientFacilityLoading, facilityPatientAddress, error } = useSelector(state => state.facilityPatientAddress)
  const {loading: postFacilityLoading, error: postFacilityAddressError} = useSelector(state => state.postFacilityAddress)
  const { advancedFiltersOptions, loading } = useSelector(state => state.advancedFiltersOptions)
  const {facilityAdress} = useSelector(state => state.getfacilityUserAddress)
  const [facilityName, setFacilityName] = useState()
  const [facilityUnit, setFacilityUnit] = useState(null)
  const [facilityRoomNo, setFacilityRoomNo] = useState(null)
  const patientId = patientInfoDetail.id

  const { addToast } = useToasts()

  const {
    reset,
    watch,
    control,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      room: facilityPatientAddress?.room || "",
      unit: facilityPatientAddress?.unit || "",
      bedPosition: facilityPatientAddress?.bedPosition || "",
      facility: facilityPatientAddress?.facilityId ?? (location?.state?.facilityId || facilityAdress?.id) 
    }
  })

  useEffect(() => {
    if(updateFacilityShow){
      (async () => {
        try {
          if(patientId){
            dispatch(getFacilityUserAddressReset())
            await dispatch(getfacilityUserAddress(patientId))  
          }
        } catch (error) {
          console.error("Error fetching facility address:", error)
        }
      })();
    }
  }, [patientId, dispatch])

  useEffect(() => {
    const subscription = watch(["facility", "unit", "room", "bedPosition"])
    return () => (subscription && subscription?.unsubscribe ? subscription.unsubscribe() : null)
  }, [watch])

  useEffect(() => {
    setValue("unit","")
    setValue("room","")
    setValue("bedPosition","")
  }, [facilityName])

  useEffect(()=>{
    setValue("room","")
    setValue("bedPosition","")
  },[facilityUnit])

  useEffect(()=>{
    setValue("bedPosition","")
  },[facilityRoomNo])

  useEffect(()=>{
    setValue("facility", facilityPatientAddress?.facilityId ?? facilityAdress?.id)
  },[facilityAdress, facilityPatientAddress])

  useEffect(()=>{
    if(updateFacilityShow)
    {
    dispatch(getAdvancedFiltersOptions(orgId))
    dispatch(getFacilityPatientAddressAction(patientInfoDetail?.id))  
    }
  },[updateFacilityShow])
  useEffect(() => {
    if(!updateFacilityShow)
    {
      dispatch({type:"RESET_FACILITY_PATIENT_ADDRESS"})
      dispatch({type:RESET_POST_PATIENT_FACILITY_ADDRESS})
      setFacilityName(null)
    }
  }, [updateFacilityShow])

  useEffect(() => {
    if (advancedFiltersOptions) {
      //  let facilityName = advancedFiltersOptions?.filter((facility)=> (facility?.facilityId === location?.state?.facilityId ) )
      // setFacilityName(facilityName?.[0].facilityId)
      // setValue('facility', facilityName?.[0]?.facilityId)   
      if (facilityPatientAddress && updateFacilityShow) {
        setValue("room", facilityPatientAddress?.room)
        setValue("unit", facilityPatientAddress?.unit)
        setValue("bedPosition", facilityPatientAddress?.bedPosition)
        setValue("facility", facilityPatientAddress?.facilityId ?? (facilityAdress?.id || location?.state?.facilityId))
      }
    }
  }, [updateFacilityShow, facilityPatientAddress, facilityAdress, loading])

  const handleClose = () => {
    reset()
    setFacilityUnit(null)
    setFacilityRoomNo(null)
    handleUpdateFacilityClose()
  }

  const onSubmit = async data => {
    let result
    if( facilityPatientAddress?.room || facilityPatientAddress?.unit 
      || facilityPatientAddress?.bedPosition || facilityPatientAddress?.id)
      {
    result = dispatch(
      updateFacilityPatientAddressAction(patientInfoDetail?.id, facilityPatientAddress?.facilityId || facilityAdress?.id || location?.state?.facilityId, {
        newFacilityId: facilityPatientAddress?.facilityId !== data.facility ? data.facility : null, // this condition for when admin want to update other obj prop not facility name
        unit:data?.unit,
        room:data?.room,
        bedLocation: bedPositionWOBed.includes(data?.bedPosition.toLowerCase())
            ? data?.bedPosition
            : `${data?.bedPosition}_bed`      
      })
    )}else {
      result = dispatch(
        postFacilityPatientAddressAction(patientInfoDetail?.id, location?.state?.facilityId || facilityAdress?.id, {
         // newFacilityId:location?.state?.facilityId !== data.facility ? data.facility : null, //when admin want to update other obj prop not facility name, here location state used to create unit config and no facility Data initially
         // newFacilityId:  data.facility,
          unit:data?.unit,
          room:data?.room,
          bedLocation: bedPositionWOBed.includes(data?.bedPosition.toLowerCase())
            ? data?.bedPosition
            : `${data?.bedPosition}_bed`      
        })
      )
    }
    result.then(res => {
      if (res?.message?.toLowerCase() === "success") {
        handleUpdateFacilityClose()
        addToast("Patient Updated Successfully", {
          appearance: "success",
          autoDismiss: true
        })
        dispatch(getPatientMenuConfigAction(patientInfoDetail?.id))
        const patientId = { patientId: patientInfoDetail?.id }
        // dispatch(getPatientInfo(patientId))
        dispatch(getFacilityPatientAddressAction(patientInfoDetail?.id))
      }
    })
  }

  const facilityOptions = advancedFiltersOptions?.filter(i => i.userType === "facility") || []

  return (
    <Modal
      width={600}
      footer={null}
      onCancel={handleClose}
      open={updateFacilityShow}
      title={<h4 className="text-xl">Update Facility</h4>}>
      {patientFacilityLoading && loading ? (
        <div className="loaderLoading">
          <Loading />
        </div>
      ) : (
        <form className="pt-4" onSubmit={handleSubmit(onSubmit)}>
          <Row gutter={24}>
            <Col xs={24} className="mb-4">
              <label className="mb-2" htmlFor="input-facility">
                Facility <span className="text-danger">*</span>
              </label>
              <Controller
                name="facility"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    size="large"
                    className="w-100 capitalize"
                    id="input-facility"
                    status={errors.facility ? "error" : undefined}
                    options={facilityOptions?.map(address => ({
                      value: address.facilityId,
                      label: <span className="capitalize">{address.name}</span>
                    }))}
                    {...field}
                    onChange={e => {
                      field.onChange(e)
                      setFacilityName(e)
                    }}
                    disabled={
                      !facilityPatientAddress?.room &&
                      !facilityPatientAddress?.unit &&
                      !facilityPatientAddress?.bedPosition
                    }
                  />
                )}
              />
              {errors.facility ? (
                <small className="text-danger">{errors.facility.message}</small>
              ) : null}
            </Col>
            <Col xs={24} className="mb-4">
              <label className="mb-2" htmlFor="input-unit">
                Unit <span className="text-danger">*</span>
              </label>
              <Controller
                name="unit"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    className="w-100"
                    status={errors.unit ? "error" : undefined}
                    size="large"
                    id="input-unit"
                    options={getUnits(facilityOptions, facilityName ?? (facilityPatientAddress?.facilityId || facilityAdress?.id || location?.state?.facilityId))
                      ?.filterOptions?.sort((a, b) =>
                        customUnitRoomSort(a.units.unit, b.units.unit)
                      )
                      ?.map(u => ({
                        value: u.units.unit,
                        label: u.units.unit
                      }))}
                    {...field}
                    onChange={e => {
                      field.onChange(e)
                      setFacilityUnit(e)
                    }}
                  />
                )}
              />
              {errors.unit ? <small className="text-danger">{errors.unit.message}</small> : null}
            </Col>
            <Col xs={24} className="mb-4">
              <label className="mb-2" htmlFor="input-room">
                Room <span className="text-danger">*</span>
              </label>
              <Controller
                name="room"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    className="w-100"
                    status={errors.room ? "error" : undefined}
                    size="large"
                    id="input-room"
                    options={getRooms(
                      facilityOptions,
                      facilityName ?? ( facilityPatientAddress?.facilityId || facilityAdress?.id || location?.state?.facilityId),
                      getValues("unit")
                    )?.room?.sort(customUnitRoomSort)
                      .map(r => ({
                        value: r,
                        label: r
                      }))}
                    {...field}
                    onChange={e => {
                      field.onChange(e)
                      setFacilityRoomNo(e)
                    }}
                  />
                )}
              />
              {errors.room ? <small className="text-danger">{errors.room.message}</small> : null}
            </Col>

            <Col xs={24} className="mb-4">
              <label className="mb-2" htmlFor="input-bedPosition">
                Bed Position <span className="text-danger">*</span>
              </label>
              <Controller
                name="bedPosition"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    className="w-100"
                    status={errors.bedPosition ? "error" : undefined}
                    size="large"
                    id="input-bedPosition"
                    options={getBedPositions(
                      facilityOptions,
                      facilityName ?? (facilityPatientAddress?.facilityId || facilityAdress?.id || location?.state?.facilityId),
                      getValues("unit")
                    )?.bedPositions?.map(r => ({
                      value: `${r}`,
                      label: r
                    }))}
                    {...field}
                  />
                )}
              />
              {errors.bedPosition ? (
                <small className="text-danger">{errors.bedPosition.message}</small>
              ) : null}
            </Col>
            {error ? (
              <Col className="mb-4" xs={24}>
                <p className="text-danger font-semibold">{error}</p>
              </Col>
            ) : null}
            {postFacilityAddressError ? (
              <Col className="mb-4" xs={24}>
                <p className="text-danger font-semibold">{postFacilityAddressError}</p>
              </Col>
            ) : null}
            <Col xs={24}>
              <div className="flex flex-wrap gap-3">
                <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  loading={patientFacilityLoading || postFacilityLoading}>
                  Submit
                </Button>
                <Button size="large" danger onClick={handleClose}>
                  Cancel
                </Button>
              </div>
            </Col>
          </Row>
        </form>
      )}
    </Modal>
  )
}

export default UpdatePatientFacility

