import { applyMiddleware, combineReducers, createStore } from "redux"
import thunk from "redux-thunk"
import { composeWithDevTools } from "redux-devtools-extension"
import {
  ImageReducer,
  userLoginReducer,
  emailUpdateReducer,
  phoneUpdateReducer,
  passwordUpdateReducer,
  validateLoginDetailsReducer,
  caretakerUpdateReducer,
  googleLoginReducer,
  postFeedbackReducer
} from "./reducers/userReducer"
import {
  criticalPatientReducer,
  dashboardKPIReducer,
  enrollmentPatientCountReducer,
  enrollmentStatsReducer,
  filterDashboardReducer,
  getUserCountStatsReducer,
  triageStatsAnalysisReducer,
  triageStatsCountReducer
} from "./reducers/dashboardReducer"
import {
  AddPatientReducer,
  addPatientClearErrorsReducer,
  chronicTypeReducer,
  criticalStatusReducer,
  getChronicConditionReducer,
  getManualTimeReducer,
  getPatientReportReducer,
  HealthBpReducer,
  HealthKpiReducer,
  HealthOxyReducer,
  HealthWmReducer,
  HealthGlucoseReducer,
  patientBpReadingReducer,
  PatientInfoReducer,
  patientOxyReadingReducer,
  PatientReadingReducer,
  patientTimelineReducer,
  patientWmReadingReducer,
  postChronicConditionReducer,
  updateLandlineReducer,
  UpdatePatientReducer,
  AddManualTime,
  addConcernReducer,
  getConcernReducer,
  getTriageCriticalPatientsReducer,
  PatientMonitoringReducer,
  PatientDiagnosticReducer,
  PostPatientDiagnosticReducer,
  PatientVitalsReducer,
  postPatientVitalsReducer,
  PatientAddressReducer,
  facilityAddressReducer,
  patientCaretakerReducer,
  postPatientCaretakerReducer,
  patientManualLogTrack,
  patientInsuranceReducer,
  updatePatientInsuranceReducer,
  outpatientAddressReducer,
  advancedFiltersOptionsReducer,
  facilityPatientAddressReducer,
  PatientCriticalDevicesReducer,
  postPatientInsuranceReducer,
  postFacilityPatientAddressReducer,
  dateRangeReducer,
  patientAdvFilterReducer,
  getPatientMenuConfigReducer,
  getPatientOptOutReducer,
  postPatientOptOutReducer,
  patientDetailsValidateReducer,
  getTriageFalseReportReducer,
  postOutpatientAddressReducer,
  getManualTimeEditHistoryReducer,
  getfacilityUserAddressReducer,
  PatientAllergyReducer
} from "./reducers/patientReducer"

import {
  addProviderReducer,
  getSingleProviderReducer,
  providerKPIReducer,
  providerPatientReducer,
  providerReducer,
  searchProviderByNPI
} from "./reducers/providerReducer"
import {
  addCaretakerReducer,
  caretakerCardReducer,
  caretakerListReducer,
  caretakerPatientReducer,
  singleCaretakerReducer,
  getCareTypesReducer,
  updateCaretakerManualTimeReducer,
  getOrgAdminsReducer,
  getCaretakerOrgDetailsReducer
} from "./reducers/caretakerReducer"
import { monitorTypeReducer } from "./reducers/devicesReducer"
import {
  emailChangeReducer,
  emailVerificationReducer,
  numberChangeReducer,
  pushNotificationReducer,
  setTriageSessionReducer,
  setShortClearReducer,
  filterModalReducer
} from "./reducers/utilReducer"
import {
  getCcmReducer,
  postCCMReducer,
  getCcmCarePlanReducer,
  getFacilityAddressReducer,
  getUserSearchReducer,
  getFilterUsersReducer,
  getUsersForCcmFollowUpReducer,
  getDashboardFilterUsersReducer,
  getCcmArchiveReducer,
  updateCcmPlanReducer,
  postCcmFollowUpReducer,
  getLatestCcmPlanReducer
} from "./reducers/ccmReducer"
import { getThemeReducer } from "./reducers/themeReducer"
import {
  getRpmReducer,
  postRpmReducer,
  getRpmCarePlanReducer,
  getUsersForRpmFollowUpReducer,
  postRpmTriageReducer,
  getRpmDeviceReducer,
  postRpmClearTriageReducer,
  getRpmTriageDataReducer,
  getTreatmentPlans,
  postCriticalReadingSnoozeReducer,
  addCriticalPatientReducer
} from "./reducers/rpmReducer"

import {
  postRTMPainManagementDataReducer,
  getRTMPainManagementDataReducer,
  getRTMPainManagementReadingReducer,
  postRTMAddictionMedicineDataReducer,
  getRTMAddictionMedicineDataReducer,
  getRTMAddictionMedicineReadingReducer,
  postRTMMentalHealthDataReducer,
  getRTMMentalHealthDataReducer,
  getRTMMentalHealthReadingReducer
} from "./reducers/rtmReducers"

import {
  AddInternalNoteReducer,
  EditInternalNoteReducer,
  getInternalNotesReducer
} from "./reducers/internalNotesReducer"
import { UploadFilesReducer } from "./reducers/uploadFileReducer"
import { getAllOrgReducer } from "./reducers/orgDetailsReducer"
import {
  AddSpecialInstructionsReducer,
  DeleteSpecialInstructionsReducer,
  EditSpecialInstructionsReducer,
  GetSpecialInstructionsHistoryReducer,
  GetSpecialInstructionsReducer
} from "./reducers/specialInstructionsReducer"
import {
  getPatientBpCustomParameterReducer,
  getPatientGlucoCustomParameterReducer,
  getPatientOxiCustomParameterReducer
} from "./reducers/customParameterReducer";
import {
  getPractitionerAddressReducer,
  getPractitionerSpecializationReducer,
  practitionerFilterPatientReducer,
  practitionerListReducer,
  practitionerPatientAdvFilterReducer,
  practitionerPatientReducer,
  singlePractitionerReducer
} from "./reducers/practitionerReducer"
import {
  getOrgOnboardPatientStatisticsReducer,
  getSelfOnboardingPendingPatientReducer,
  postBulkMailMessagePatientReducer,
  updateBulkCaretakerActivePatientReducer,
  updateSelfOnboardingPatientCaretakerReducer
} from "./reducers/selfOnboardingReducer"
import {
  getPatientStatusReducer,
  getShipmentStatusReducer,
  updatePatientStatusReducer,
  updateShipmentStatusReducer
} from "./reducers/patientStatusReducer"
import {
  getLatestPcmPlanReducer,
  getPcmArchiveReducer,
  getPcmCarePlanReducer,
  getPcmReducer,
  postPCMReducer,
  postPcmFollowUpReducer,
  updatePcmPlanReducer,
} from "./reducers/pcmReducer"
import {
  addGroupReducer,
  getAdminCaretakerForGroupReducer,
  getGroupDetailReducer,
  getGroupsReducer,
  updateGroupReducer,
  mapGroupToPatientReducer,
  validateGroupNameReducer,
  getPatientsForGroupReducer,
  groupPatientAdvFilterReducer,
  groupPatientsListAdvFilterReducer,
  getPatientGroupNameReducer
} from "./reducers/groupsReducer"
import { getLastReadingDateAnalysis } from "./reducers/LastReadingTakenAnalysisReducer"
import { getUserSearchnReducer } from "./reducers/userSearchReducer"

const reducer = combineReducers({
  uploadFiles: UploadFilesReducer,
  userInfo: userLoginReducer,
  googleLogin: googleLoginReducer,
  themeConfig: getThemeReducer,
  imageUploader: ImageReducer,
  passwordUpdate: passwordUpdateReducer,
  validateLoginDetails: validateLoginDetailsReducer,
  userEmailUpdate: emailUpdateReducer,
  userPhoneUpdate: phoneUpdateReducer,
  caretakerUpdate: caretakerUpdateReducer,
  dashboardKPI: dashboardKPIReducer,
  enrollmentStats: enrollmentStatsReducer,
  enrollmentPatientCount: enrollmentPatientCountReducer,
  triageStatsAnalysis: triageStatsAnalysisReducer,
  triageStatsCount: triageStatsCountReducer,
  filterdashboardKPI: filterDashboardReducer,
  criticalPatient: criticalPatientReducer,
  manualList: getManualTimeReducer,
  manualTimeEditHistory: getManualTimeEditHistoryReducer,
  chronicConcern: getChronicConditionReducer,
  criticalStatus: criticalStatusReducer,
  // PATINET_DATA
  addSpecialInstructions: AddSpecialInstructionsReducer,
  getSpecialInstructions: GetSpecialInstructionsReducer,
  editSpecialInstructions: EditSpecialInstructionsReducer,
  GetSpecialInstructionsHistory: GetSpecialInstructionsHistoryReducer,
  deleteSpecialInstructions: DeleteSpecialInstructionsReducer,
  addInternalNote: AddInternalNoteReducer,
  editInternalNote: EditInternalNoteReducer,
  getInternalNotes: getInternalNotesReducer,
  patientInfo: PatientInfoReducer,
  patientMonitoringData: PatientMonitoringReducer,
  patientDiagnosticData: PatientDiagnosticReducer,
  postPatientDiagnosticReducer: PostPatientDiagnosticReducer,
  patientVitals: PatientVitalsReducer,
  postPatientVitals: postPatientVitalsReducer,
  patientReport: getPatientReportReducer,
  healthKpi: HealthKpiReducer,
  healthWm: HealthWmReducer,
  healthBp: HealthBpReducer,
  healthOxy: HealthOxyReducer,
  healthGlucose: HealthGlucoseReducer,
  patientReading: PatientReadingReducer,
  patientTimeline: patientTimelineReducer,
  weightMachineReading: patientWmReadingReducer,
  oxygenMeterReading: patientOxyReadingReducer,
  bloodPressureReading: patientBpReadingReducer,
  addPatient: AddPatientReducer,
  addPatientErrors: addPatientClearErrorsReducer,
  updatePatient: UpdatePatientReducer,
  chronicDeviceType: chronicTypeReducer,
  chronicConditionStatus: postChronicConditionReducer,
  addManualTime: AddManualTime,
  updateLandline: updateLandlineReducer,
  patientConcern: addConcernReducer,
  patientConcernData: getConcernReducer,
  triageCriticalPatients: getTriageCriticalPatientsReducer,
  patientAddress: PatientAddressReducer,
  facilityAddress: facilityAddressReducer,
  patientCaretaker: patientCaretakerReducer,
  postPatientCaretaker: postPatientCaretakerReducer,
  patientManualLogTrack: patientManualLogTrack,
  patientInsurance: patientInsuranceReducer,
  updatePatientInsurance: updatePatientInsuranceReducer,
  outpatientAddress: outpatientAddressReducer,
  postOutpatientAddress: postOutpatientAddressReducer,
  advancedFiltersOptions: advancedFiltersOptionsReducer,
  facilityPatientAddress: facilityPatientAddressReducer,
  patientCriticalDevices: PatientCriticalDevicesReducer,
  postFacilityAddress: postFacilityPatientAddressReducer,
  getTriageFalseReport: getTriageFalseReportReducer,
  selectedDateRange: dateRangeReducer,
  patientAdvFilter: patientAdvFilterReducer,
  postPatientInsurance: postPatientInsuranceReducer,
  getPatientMenuConfig: getPatientMenuConfigReducer,
  getPatientOptOut: getPatientOptOutReducer,
  postPatientOptOut: postPatientOptOutReducer,
  patientDetailsValidate: patientDetailsValidateReducer,
  getUserSearch: getUserSearchnReducer,
  getfacilityUserAddress: getfacilityUserAddressReducer,
  // patient status
  getPatientStatus: getPatientStatusReducer,
  updatePatientStatus: updatePatientStatusReducer,
  getShipmentStatus: getShipmentStatusReducer,
  updateShipmentStatus: updateShipmentStatusReducer,
  // Org details
  orgDetails: getAllOrgReducer,
  lastReadingAnalysis: getLastReadingDateAnalysis,
  // DASHBOARD
  getUserCountStats: getUserCountStatsReducer,
  // PROVIDER_DETAILS
  providerList: providerReducer,
  providerKPI: providerKPIReducer,
  providerPatient: providerPatientReducer,
  addProvider: addProviderReducer,
  providerNPI: searchProviderByNPI,
  singleProvider: getSingleProviderReducer,
  // PRACTITIONER_DETAILS
  practitionerList: practitionerListReducer,
  singlePractitioner: singlePractitionerReducer,
  practitionerPatient: practitionerPatientReducer,
  practitionerFilterPatient: practitionerFilterPatientReducer,
  practitionerPatientAdvFilter: practitionerPatientAdvFilterReducer,
  practitionerSpecialization: getPractitionerSpecializationReducer,
  practitionerAddress: getPractitionerAddressReducer,
  // CATETAKER_DATA
  caretakerCard: caretakerCardReducer,
  caretakerList: caretakerListReducer,
  singleCaretaker: singleCaretakerReducer,
  caretakerPatient: caretakerPatientReducer,
  addCaretaker: addCaretakerReducer,
  careTypes: getCareTypesReducer,
  cateTakerManualtime: updateCaretakerManualTimeReducer,
  orgAdmins: getOrgAdminsReducer,
  caretakerOrgDetails: getCaretakerOrgDetailsReducer,
  // devices details
  monitorTypeList: monitorTypeReducer,

  // utils
  emailVerification: emailVerificationReducer,
  emailUpdate: emailChangeReducer,
  numberUpdate: numberChangeReducer,
  pushNotification: pushNotificationReducer,
  sessionTriageData: setTriageSessionReducer,
  shortClearData: setShortClearReducer,
  filterModalValues: filterModalReducer,

  // CCM
  ccmList: getCcmReducer,
  getLatestCareplan: getLatestCcmPlanReducer,
  updateCcmPlan: updateCcmPlanReducer,
  ccmArchivePlans: getCcmArchiveReducer,
  postCCMData: postCCMReducer,
  postCcmFollowUp: postCcmFollowUpReducer,
  ccmPatientCarePlan: getCcmCarePlanReducer,
  // facilityAddress: getFacilityAddressReducer,
  filterSearch: getUserSearchReducer,
  filter: getFilterUsersReducer,
  ccmFollowUpUsers: getUsersForCcmFollowUpReducer,
  //previousCareplans: getPreviousCareplansListReducer,  

  // PCM 
  pcmList: getPcmReducer,
  getLatestPcmCareplan: getLatestPcmPlanReducer,
  updatePcmPlan: updatePcmPlanReducer,
  postPCMData: postPCMReducer,
  pcmArchivePlans: getPcmArchiveReducer,
  postPcmFollowUp: postPcmFollowUpReducer,
  getPcmCarePlan: getPcmCarePlanReducer,
  // RPM
  rpmList: getRpmReducer,
  postRPMData: postRpmReducer,
  rpmPatientCarePlan: getRpmCarePlanReducer,
  rpmFollowUpUsers: getUsersForRpmFollowUpReducer,
  rpmTriageStore: postRpmTriageReducer,
  rpmDevices: getRpmDeviceReducer,
  rpmClearTriage: postRpmClearTriageReducer,
  rpmTriageData: getRpmTriageDataReducer,
  treatmentPlans: getTreatmentPlans,
  postCriticalReadingSnooze: postCriticalReadingSnoozeReducer,
  addCriticalPatient: addCriticalPatientReducer,
  // RTM - Pain Management
  postRTMPainManagementData: postRTMPainManagementDataReducer,
  getRTMPainManagementData: getRTMPainManagementDataReducer,
  getRTMPainManagementReading: getRTMPainManagementReadingReducer,
  // RTM - Addiction Medicine
  postRTMAddictionMedicineData: postRTMAddictionMedicineDataReducer,
  getRTMAddictionMedicineData: getRTMAddictionMedicineDataReducer,
  getRTMAddictionMedicineReading: getRTMAddictionMedicineReadingReducer,
  // RTM - Mental Health
  postRTMMentalHealthData: postRTMMentalHealthDataReducer,
  getRTMMentalHealthData: getRTMMentalHealthDataReducer,
  getRTMMentalHealthReading: getRTMMentalHealthReadingReducer,
  // selfonboarding 
  getOrgOnboardPatientStatistics: getOrgOnboardPatientStatisticsReducer,
  selfOnboardingPendingPatient: getSelfOnboardingPendingPatientReducer,
  updateBulkCaretaker: updateSelfOnboardingPatientCaretakerReducer,
  updateBulkCaretakerActivePatient: updateBulkCaretakerActivePatientReducer,
  postBulkMessagePatient: postBulkMailMessagePatientReducer,
  // custom parameter
  getPatientBpCustomParameter: getPatientBpCustomParameterReducer,
  getPatientOxiCustomParameter: getPatientOxiCustomParameterReducer,
  getPatientGlucoCustomParameter: getPatientGlucoCustomParameterReducer,
  // groups
  getGroups: getGroupsReducer,
  addGroup: addGroupReducer,
  getGroupDetail: getGroupDetailReducer,
  updateGroup: updateGroupReducer,
  getAdminCaretakerForGroup: getAdminCaretakerForGroupReducer,
  mapGroupToPatient: mapGroupToPatientReducer,
  validateGroupName: validateGroupNameReducer,
  getPatientsForGroup: getPatientsForGroupReducer,
  groupPatientAdvFilter: groupPatientAdvFilterReducer,
  groupPatientsListAdvFilter: groupPatientsListAdvFilterReducer,
  getPatientGroupName: getPatientGroupNameReducer,
  // feedback
  postFeedback: postFeedbackReducer,
  // Allergy
  patientAllergy: PatientAllergyReducer,
})

const middleware = [thunk]

const store = createStore(reducer, composeWithDevTools(applyMiddleware(...middleware)))
// const store = createStore(reducer, applyMiddleware(...middleware));

export default store
