import React, { useEffect, useState, Fragment } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"

import { useDispatch, useSelector } from "react-redux"
import { getccmCareplanDataActions } from "../../redux/actions/ccmActions"
import {
  getPatientInfo,
  getPatientVitals,
  getLatestReadings,
  getPatientCaretaker,
  getPatientMonitoringData,
  getPatientCriticalDevices,
  getPatientInsurance,
  getOutPatientAddressAction,
  getAdvancedFiltersOptions,
  getFacilityPatientAddressAction,
  getPatientDiagnosticsData,
  getPatientMenuConfigAction,
  getPatientOptoutAction,
  getTriageFalseReport,
  getPatientAllergyData
} from "../../redux/actions/patientAction"

import { Tabs, Alert, Row, Col, Card, Button, Tag, Spin } from "antd"

import moment from "moment"

import ReadingModal from "./PatientModal/ReadingDiv"
import UpdatePatientLandline from "./PatientModal/UpdatePatientLandline"

import UpdateMonitoring from "./PatientModal/UpdateMonitoring"
import UpdatePatientVitals from "./PatientModal/UpdatePatientVitals"
import UpdateOutPatientAddress from "./PatientModal/UpdateOutPatientAddress"
import UpdatePatientDiagnostics from "./PatientModal/UpdatePatientDiagnostics"
import UpdatePatientBasicDetails from "./PatientModal/UpdatePatientBasicDetails"
import UpdatePatientFacility from "./PatientModal/UpdatePatientFacility"

import PatientBPComponent from "./PatientBP"
import PatientDataComponent from "./PatientData"
import Loading from "../layouts/Loading/Loading"
import PatientOxymeterComponent from "./PatientOxymeter"
import PatientGlucoseMeterComponent from "./PatientGlucoseMeter"
import HandleApiFail from "../layouts/APIFail/ApiFail"
import "./patient.css"
import InsuranceListModal from "./PatientModal/InsuranceListModal"
import AddUpdateInsurance from "./PatientModal/AddUpdateInsurance"
import { getrpmDeviceActions } from "../../redux/actions/rpmAction"
import { Mixpanel } from "../../utils/mixpanel"
import { GetSpecialInstruction } from "../../redux/actions/specialInstructionsActions"
import { ExclamationCircleFilled } from "@ant-design/icons"
import formatPhoneNumber from "../../utils/formatPhoneNumber"
import UpdatePatientStatus from "./PatientModal/UpdatePatientStatus"
import { getPatientStatusAction, getShipmentStatusAction } from "../../redux/actions/patientStatusAction"
import { UPDATE_PATIENT_STATUS_RESET, UPDATE_SHIPPING_STATUS_RESET } from "../../redux/types/patientStatusConstant"
import { GetGroups, GetPatientGroupName } from "../../redux/actions/groupsActions"
import AssignGroupToPatient from "./AssignGroupToPatient"
import { prettifyHeight } from "../../utils/heightConversion"
import ProfileAvatar from "../../utils/ProfileAvatar"

const currentDate = moment().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
const durationObj = {
  week: moment.utc(currentDate).subtract(7, "days").startOf("day").toDate().toISOString(),
  month: moment.utc(currentDate).subtract(1, "month").startOf("day").toDate().toISOString(),
  year: moment.utc(currentDate).subtract(1, "year").startOf("day").toDate().toISOString()
}

const WEIGHT_MACHINE = "weight machine"
const BLOOD_PRESSURE_MACHINE = "blood pressure machine"
const OXIMETER = "oximeter"
const GLUCOSE_MACHINE = "glucose machine"
const NO_CRITICAL_DEVICE_MSG = "No Critical Device Found!"

const getProgramId = (arr, programName) =>
  arr?.find(device => device.programs.programName === programName)

const PatientDetailInfo = () => {
  const [updateApiCalls, setUpdateApiCalls] = useState(false)
  const [showUpdateModal, setShowUpdateModal] = useState(false)
  const [showReadingModal, setShowReadingModal] = useState(false)
  const [statusUpdateShow, setStatusUpdateShow] = useState(false)
  const [updateVitalsShow, setUpdateVitalsShow] = useState(false)
  const [updatePatientShow, setUpdatePatientShow] = useState(false)
  const [showLandlineModal, setShowLandlineModal] = useState(false)
  const [updateMonitoringShow, setUpdateMonitoringShow] = useState(false)
  const [showPhoneNumberModal, setShowPhoneNumberModal] = useState(false)
  const [updateDiagnosticsShow, setUpdateDiagnosticsShow] = useState(false)
  const [updateAddressShow, setUpdateAddressShow] = useState(false)
  const [updateFacilityShow, setUpdateFacilityShow] = useState(false)
  const [showPatientInsuranceList, setShowPatientInsuranceList] = useState(false)
  const [insuranceListApiCalls, setInsuranceListApiCalls] = useState(false)
  const [selectedInsurance, setSelectedInsurance] = useState(null)
  const [showUpdatePatientInsurance, setShowUpdatePatientInsurance] = useState(false)
  const [insuranceAction, setInsuranceAction] = useState(null)
  const [patientGrpName, setPatientGrpName] = useState([]);

  const [assignedProviders, setAssignedProviders] = useState({
    rpmProviderList: [],
    ccmProviderList: [],
    pcmProviderList: []
  })
  const [assignedPractitioners, setAssignedPractitioners] = useState({
    rpmPractitionerList: [],
    ccmPractitionerList: [],
    pcmPractitionerList: []
  })

  const [readingMonth, setReadingMonth] = useState(new Date().getMonth() + 1)
  const [chronicCondition, setChronicCondition] = useState([])
  const [showWm, setShowWm] = useState(false)
  const [showBp, setShowBp] = useState(false)
  const [showOxi, setShowOxi] = useState(false)
  const [showGluco, setShowGluco] = useState(false)

  const [showActions, setShowActions] = useState(false)
  const [bpCustomParameterTab, setBpCustomParameterTab] = useState(false);
  const [oxiCustomParameterTab, setOxiCustomParameterTab] = useState(false);
  const [ptMenuConfig, setptMenuConfig] = useState();
  const [showGroupButton, setShowGroupButton] = useState(false)
  const [assignableGroups, setAssignableGroups] = useState([])
  const [showAssignGroupModal, setShowAssignGroupModal] = useState(false)

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { id: patientId, orgId, orgName } = useParams()
  const location = useLocation()

  const { patientVitals } = useSelector(state => state.patientVitals)
  const { patientLatestReading } = useSelector(state => state.patientReading)
  const { patientMonitoringData, loading: monitoringLoading } = useSelector(
    state => state.patientMonitoringData
  )
  const { triageFalseReport: criticalDevices, loading: criticalDevicesLoading } = useSelector(state => state.getTriageFalseReport);
  const { loading: icdCodesLoading, patientDiagnosticData } = useSelector(state => state.patientDiagnosticData);
  const { patientInsurance } = useSelector(state => state.patientInsurance)
  const {
    loading: patientLoading,
    patientInfoDetail,
    error: patientInfoError
  } = useSelector(state => state.patientInfo)
  const { loading: caretakerLoading, patientCaretaker } = useSelector(
    state => state.patientCaretaker
  )
  const { patientStatus, loading: patientStatusLoading } = useSelector(
    state => state.getPatientStatus
  )
  const { shipmentStatus, loading: shippingtStatusLoading } = useSelector(
    state => state.getShipmentStatus
  )

  const userInfo = useSelector(state => state.userInfo)
  // const monitorTypes = JSON.parse(localStorage.getItem("monitorTypes"))

  const handleShowAllActionShow = () => {
    navigate(`/${orgId}/${orgName}/patient-more-details/${patientId}`, {
      state: {
        ...location?.state,
        patientInfo: patientInfoDetail,
        monitoringData: patientMonitoringData
      }
    })
  }

  const { providerList } = useSelector(state => state.providerList)
  const { loading: menuConfigLoading, patientMenuConfig } = useSelector(state => state.getPatientMenuConfig)
  const { patientGroupName } = useSelector(state => state.getPatientGroupName)
  const { rpmDevices: monitorTypes } = useSelector(state => state.rpmDevices)

  // useEffect(() => {
  //   dispatch(getLatestReadings(patientId))
  // }, [dispatch, patientId])
  useEffect(() => {
    setptMenuConfig(patientMenuConfig)
    let tempGroupName = patientGroupName?.map(({ name }) => name)
    setPatientGrpName(tempGroupName)
  }, [patientMenuConfig, patientGroupName])

  const { specialInstructions } = useSelector(store => store.getSpecialInstructions)
  const { patientOptOut } = useSelector(store => store.getPatientOptOut)
  const { groups, loading } = useSelector((state) => state.getGroups)
  // Allergy
  const { patientAllergyData } = useSelector(state => state.patientAllergy)
  const allergyTypes = [
    "Other Allergies",
    "Food Allergies",
    "Medication Allergies",
    "Environmental Allergies",
    "Insect Allergies",
    "Chemical & Skin Allergies"
  ];

  const patientAllergies = allergyTypes.reduce((acc, type) => {
    const allergies = patientAllergyData?.[type] || [];
    return acc.concat(allergies);
  }, []);
  
  useEffect(() => {
    if (patientId) {
      dispatch(GetSpecialInstruction({ patientId }))
      dispatch(getPatientMenuConfigAction(patientId))
      dispatch(getPatientOptoutAction(patientId))
      dispatch(GetPatientGroupName(patientId))
      dispatch(GetGroups(userInfo?.userInfo?.id))
      dispatch(getPatientAllergyData(patientId))
    }
  }, [patientId])

  useEffect(() => {
    const managerInGroups = groups?.filter(group => {
      const managerIds = group?.managers?.map(manager => manager?.id)
      if (managerIds.includes(userInfo?.userInfo?.id)) {
        return group
      }
    })
    if (managerInGroups && managerInGroups?.length > 0) {
      setShowGroupButton(true)
      setAssignableGroups(managerInGroups)
    }
  }, [groups])

  // to clear errors if any for patient status update
  useEffect(() => {
    dispatch({ type: UPDATE_SHIPPING_STATUS_RESET })
    dispatch({ type: UPDATE_PATIENT_STATUS_RESET })
  }, [])

  useEffect(() => {
    if (!patientId || !patientStatus?.equipmentStatus) return
    const equipmentStatus = patientStatus?.equipmentStatus?.toLowerCase()?.trim()
    if (
      equipmentStatus === "in transit" ||
      equipmentStatus === "delivered" ||
      equipmentStatus === "equipment active" ||
      equipmentStatus === "facing equipment issue" ||
      equipmentStatus === "equipment missing"
    ) {
      dispatch(getShipmentStatusAction(patientId))
    }
  }, [patientId, patientStatus])

  useEffect(() => {
    // const devices = new Set()
    // if (patientInfoDetail?.moniteringDetails) {
    //   for (let i = 0; i < patientInfoDetail?.moniteringDetails?.length; i++) {
    //     devices.add(patientInfoDetail?.moniteringDetails?.[i]?.moniterData?.name)
    //   }
    //   setDeviceData(Array.from(devices))
    // }
    // // if (patientInfoDetail?.userType === "outpatient") {
    //   dispatch(getOutPatientAddressAction(patientId))
    // } else {
    //   dispatch(getFacilityPatientAddressAction(patientId))
    // }

    if (patientInfoDetail?.critical) {
      dispatch(getTriageFalseReport(patientId))
    }
  }, [patientId, patientInfoDetail])

  // const userStatusMessage = () => {
  //   if (patientInfoDetail?.criticalStatus) {
  //     return (
  //       <div>
  //         <h4 className="mb-3 text-xl"> Patient is in Critical Situation</h4>
  //         <h6 className="text-lg mb-2">Critical Devices:</h6>
  //         {patientInfoDetail?.criticalStatus?.types?.map((opt, index) => (
  //           <p key={index} className="capitalize mb-2">
  //             {Number(index) + 1}. {opt?.criticalType}
  //           </p>
  //         ))}
  //       </div>
  //     )
  //   }
  //   return null
  // }
  const getCriticalDevices = () => {
    const uniqueDeviceTypes = [
      ...new Set(
        criticalDevices?.filter(device => device.status).map(({ deviceType }) => deviceType)
      )
    ]

    return (
      <div>
        <h4 className="mb-3 text-xl"> Patient is in Critical Situation</h4>
        <h6 className="text-lg mb-2 flex justify-between">
          Critical Devices: {criticalDevicesLoading && !criticalDevices && "Loading..."}
          <Button
            type="primary"
            className="px-3 mr-4 shadow"
            onClick={() =>
              navigate(`/${orgId}/${orgName}/critical-patient-readings/${patientId}`, {
                state: {
                  name: `${patientInfoDetail?.firstName} ${patientInfoDetail?.middleName ?? ""} ${
                    patientInfoDetail?.lastName
                  }`
                }
              })
            }>
            Triage
          </Button>
        </h6>
        {uniqueDeviceTypes.length > 0 ? (
          uniqueDeviceTypes.map((device, index) => (
            <p key={index} className="capitalize mb-2">
              {Number(index) + 1}. {device}
            </p>
          ))
        ) : (
          <p>{!criticalDevicesLoading ? NO_CRITICAL_DEVICE_MSG : ""}</p>
        )}
      </div>
    )
  }  

  const Page404 = () => {
    return (
      <div className="flex items-center justify-center flex-col">
        <h1 className="mb-4 text-6xl text-current">Patient not found</h1>
        <img alt="404" width={600} className="img-fluid" src="/assets/404.png" />
        <Button type="primary" className="px-3 shadow my-3" onClick={() => navigate(`/${orgId}/${orgName}/patient`)}>
          Back to Patient List
        </Button>
      </div>
    )
  }

  const getCcmPlan = async () => {
    // const ccData = await dispatch(getccmCareplanDataActions(patientId))
    // setChronicCondition(ccData?.careplanDetails)
  }

  // useEffect(() => {
  //   if (updateMonitoringShow) {
  //     dispatch(getPatientMonitoringData({ patientId }))
  //   }
  // }, [updateMonitoringShow, dispatch])
  useEffect(() => {
    getCcmPlan()
  }, [patientId]) //eslint-disable-line

  useEffect(() => {
    // dispatch(getWmDetails({ patientId }))
    if (!monitorTypes?.length) dispatch(getrpmDeviceActions())
    dispatch(getPatientInfo({ patientId }))
    dispatch(getPatientMonitoringData({ patientId }))
    dispatch(getPatientVitals(patientId))
    dispatch(getPatientCaretaker(patientId))
    // dispatch(getPatientInsurance(patientId))
    // dispatch(getAdvancedFiltersOptions())
  }, [dispatch, patientId])

  useEffect(() => {
    if (!updateMonitoringShow && !patientDiagnosticData?.length)
      dispatch(getPatientDiagnosticsData({ patientId }))
  }, [dispatch])

  useEffect(() => {
    dispatch(getLatestReadings(patientId))
    dispatch(getPatientStatusAction(patientId))
  }, [])

  useEffect(() => {
    const programDevice = monitorTypes?.find(({ name }) => name.toLowerCase() === "rpm")?.programs
    if (patientMonitoringData && patientMonitoringData?.rpm && monitorTypes?.length) {
      patientMonitoringData?.rpm?.map(({ programs }) =>
        programs?.map(({ programId, programName }) => {
          if (
            programDevice?.find(
              ({ id, name }) => id === programId && name.toLowerCase() === programName.toLowerCase()
            )
          ) {
            if (programName.toLowerCase() === WEIGHT_MACHINE) setShowWm(true)
            else if (programName.toLowerCase() === BLOOD_PRESSURE_MACHINE) setShowBp(true)
            else if (programName.toLowerCase() === OXIMETER) setShowOxi(true)
            else if (programName.toLowerCase() === GLUCOSE_MACHINE) setShowGluco(true)
          }
        })
      )
    } else {
      setShowWm(false)
      setShowBp(false)
      setShowOxi(false)
      setShowGluco(false)
    }
  }, [patientMonitoringData, patientInfoDetail, monitorTypes])

  const patientAge = Math.floor(
    (new Date() - new Date(patientInfoDetail?.dob).getTime()) / 3.15576e10
  )

  /*  eslint-disable */

  const heightInInches = patientVitals
    ? [...patientVitals]
        ?.sort((a, b) => new Date(b.date) - new Date(a.date))
        ?.find(vitalInfo => vitalInfo && vitalInfo?.vitalType === "height")?.value
    : null
    
  const latestWeightReading = patientVitals
    ? [...patientVitals]
      ?.sort((a, b) => new Date(b.date) - new Date(a.date))
      ?.find(vitalInfo => vitalInfo && vitalInfo?.vitalType === "weight")
    : null
  
  /*  eslint-enable */

  // const handleShowPhoneNumberModal = () => {
  //   setShowPhoneNumberModal(true)
  //   setShowActions(false)
  // }
  // const handleShowPhoneNumberModalClose = () => setShowPhoneNumberModal(false)

  // const handleUpdateEmailModalShow = () => {
  //   setShowUpdateModal(true)
  //   setShowActions(false)
  // }
  // const handleUpdateEmailModalClose = () => setShowUpdateModal(false)

  const handleUpdatePatientClose = () => setUpdatePatientShow(false)
  const handleUpdatePatientShow = () => {
    setUpdatePatientShow(true)
    setUpdateApiCalls(true)
    setShowActions(false)
  }

  // const handleShowLandlineModal = () => {
  //   setShowLandlineModal(true)
  //   setShowActions(false)
  // }
  // const handleShowLandlineModalClose = () => setShowLandlineModal(false)

  // const handleUpdateDiagnosticsClose = () => setUpdateDiagnosticsShow(false)
  // const handleUpdateDiagnosticsShow = () => {
  //   setUpdateDiagnosticsShow(true)
  //   setShowActions(false)
  // }

  const handleStatusUpdateClose = () => setStatusUpdateShow(false)
  const handleStatusUpdateShow = () => {
    setStatusUpdateShow(true)
  }

  const handleUpdateMonitoringClose = () => setUpdateMonitoringShow(false)
  const handleUpdateMonitoringShow = () => {
    setUpdateMonitoringShow(true)
    setShowActions(false)
  }

  const handleAddUpdateInsuranceListClose = () => {
    setShowPatientInsuranceList(false)
  }
  const handleAddManualListShow = () => {
    // setShowUpdatePatientInsurance(true)
    setShowPatientInsuranceList(true)
    setInsuranceListApiCalls(true)
  }

  const handleUpdateVitalsClose = () => setUpdateVitalsShow(false)
  const handleUpdateVitalsShow = () => {
    setUpdateVitalsShow(true)
    setShowActions(false)
  }

  const handleUpdateAddressClose = () => setUpdateAddressShow(false)
  const handleUpdateAddressShow = () => setUpdateAddressShow(true)

  const handleUpdateFacilityClose = () => setUpdateFacilityShow(false)
  const handleUpdateFacilityShow = () => setUpdateFacilityShow(true)
  // const rpmDevices = patientInfoDetail?.moniteringDetails?.filter(
  //   x => x?.moniterData?.name === "rpm"
  // )

  const items = patientInfoDetail => [
    patientInfoDetail !== undefined &&
    showWm && {
      key: "wm",
      label: "Weight Machine",
      children: (
        <PatientDataComponent
          patientId={patientId}
          currentDate={currentDate}
          durationObj={durationObj}
          deviceData={getProgramId(patientMonitoringData?.rpm, "weight machine")}
        />
      )
    },
    patientInfoDetail !== undefined &&
    showBp && {
      key: "bp",
      label: `Blood Pressure Machine ${bpCustomParameterTab ? "(Custom Parameter Active)" : ""}`,
      children: (
        <PatientBPComponent
          patientId={patientId}
          currentDate={currentDate}
          durationObj={durationObj}
          deviceData={getProgramId(patientMonitoringData?.rpm, "blood pressure machine")}
          setBpCustomParameterTab={setBpCustomParameterTab}
        />
      )
    },
    patientInfoDetail !== undefined &&
    showOxi && {
      key: "oxi",
      label: `Oximeter Readings ${oxiCustomParameterTab ? "(Custom Parameter Active)" : ""}`,
      children: (
        <PatientOxymeterComponent
          patientId={patientId}
          currentDate={currentDate}
          durationObj={durationObj}
          deviceData={getProgramId(patientMonitoringData?.rpm, "oximeter")}
          setOxiCustomParameterTab={setOxiCustomParameterTab}
        />
      )
    },
    patientInfoDetail !== undefined &&
    showGluco && {
      key: "glu",
      label: "Glucose Meter",
      children: (
        <PatientGlucoseMeterComponent
          patientId={patientId}
          currentDate={currentDate}
          durationObj={durationObj}
          deviceData={getProgramId(patientMonitoringData?.rpm, "glucose machine")}
        />
      )
    }
  ]

  const onChange = (key) => {
    const tabName = (key === "glu") ? "Glucose" : (key === "oxi") ? "Oximeter" : (key === "bp") ? "Blood Pressure" : "Weight Machine"
    Mixpanel.track(`${tabName} Readings Clicked`)
  };

  useEffect(() => {
    if (!patientMonitoringData) return
    const rpmProviderList = []
    const ccmProviderList = []
    const pcmProviderList = []
    Object?.keys(patientMonitoringData).forEach(key =>
      patientMonitoringData?.[key]
        ?.filter(({ providerId }) => providerId !== null)
        ?.forEach(program => {
          if (program?.monitoringType?.toLowerCase() === "rpm") {
            program.programs.forEach(
              () =>
                !rpmProviderList?.includes(
                  `${program?.providerfirstName + " " + program?.providerLastName}`
                ) &&
                rpmProviderList.push(
                  `${program?.providerfirstName + " " + program?.providerLastName}`
                )
            )
          } else if (program?.monitoringType?.toLowerCase() === "ccm") {
            ccmProviderList.push(`${program?.providerfirstName + " " + program?.providerLastName}`)
          } else if (program?.monitoringType?.toLowerCase() === "pcm") {
            pcmProviderList.push(`${program?.providerfirstName + " " + program?.providerLastName}`)
          }
        })
    )
    setAssignedProviders({ rpmProviderList, ccmProviderList, pcmProviderList })
  }, [patientMonitoringData])

  useEffect(() => {
    if (!patientMonitoringData) return
    const rpmPractitionerList = []
    const ccmPractitionerList = []
    const pcmPractitionerList = []
    Object?.keys(patientMonitoringData).forEach(key =>
      patientMonitoringData?.[key]
        ?.filter(({ practitionerId }) => practitionerId !== null)
        ?.forEach(program => {
          if (program?.monitoringType?.toLowerCase() === "rpm") {
            program.programs.forEach(
              () =>
                !rpmPractitionerList?.includes(
                  `${program?.practitionerfirstName + " " + program?.practitionerLastName}`
                ) &&
                rpmPractitionerList.push(
                  `${program?.practitionerfirstName + " " + program?.practitionerLastName}`
                )
            )
          } else if (program?.monitoringType?.toLowerCase() === "ccm") {
            ccmPractitionerList.push(`${program?.practitionerfirstName + " " + program?.practitionerLastName}`)
          } else if (program?.monitoringType?.toLowerCase() === "pcm") {
            pcmPractitionerList.push(`${program?.practitionerfirstName + " " + program?.practitionerLastName}`)
          }
        })
    )
    setAssignedPractitioners({ rpmPractitionerList, ccmPractitionerList, pcmPractitionerList })
  }, [patientMonitoringData])

  return patientLoading ? (
    <Loading />
  ) : !patientInfoDetail ? (
    patientInfoError?.status === 404 ? (
      Page404()
    ) : (
      <HandleApiFail route={`/${orgId}/${orgName}/patient`} />
    )
  ) : (
    <>
      <Row gutter={24}>
        <Col xs={24} lg={16}>
          <Card className="mb-4">
            <div className="flex gap-3 flex-wrap justify-between">
              <h4 className="text-2xl">Profile</h4>

              <Button onClick={handleShowAllActionShow}>Show More</Button>
            </div>
            <div className="mt-5 flex gap-3 flex-wrap justify-between items-center">
              <div className="flex flex-col justify-center items-start gap-2">
                <div className="flex items-center gap-3">
                  <ProfileAvatar size={100} user={patientInfoDetail} />
                  <div>
                    <h5 className="text-2xl capitalize">
                      {`${patientInfoDetail?.firstName} ${patientInfoDetail?.middleName ?? ""} ${patientInfoDetail?.lastName
                        }`}
                    </h5>
                    {patientInfoDetail?.email ? (
                      <p className="text-sm text-secondary my-1">{patientInfoDetail?.email}</p>
                    ) : null}

                    {patientInfoDetail?.mobileNumber ? (
                      <p className="text-sm text-secondary">{formatPhoneNumber(patientInfoDetail?.mobileNumber)}</p>
                    ) : null}
                    {patientInfoDetail?.specifiedParameter ? (
                      <Tag bordered={false} color="success" className="mt-1 p-1 text-[12px]">Custom Parameters Active</Tag>
                    ) : null}
                  </div>
                </div>
                {patientOptOut !== null && patientOptOut?.optOut ?
                  <div className="max-w-[250px]">
                    <p className="text-danger text-base">Opted out of services</p>
                    <p className="text-danger  break-words">Reason: {patientOptOut?.notes}</p>
                  </div> : null
                }
              </div>
              <div>
                {patientInfoDetail?.userName ? (
                  <div className="flex items-center mb-2">
                    <span className="font-medium min-w-[100px]">UserName : </span>
                    {patientInfoDetail?.userName}
                  </div>
                ) : null}

                {patientVitals ? (
                  <div className="flex items-center">
                    <span className="font-medium min-w-[100px]">Weight: </span>
                    {latestWeightReading ? (
                      <Fragment>{latestWeightReading?.value?.toFixed(1)} LB</Fragment>
                    ) : null}
                  </div>
                ) : null}
                <div className="flex items-center my-2">
                  <span className="font-medium min-w-[100px]">Age: </span>
                  {patientAge} Years Old
                </div>
                {patientVitals && heightInInches ? (
                  <div className="flex items-center capitalize">
                    <span className="font-medium min-w-[100px]">Height: </span>
                    {prettifyHeight(heightInInches)}
                  </div>
                ) : null}

                {patientInfoDetail?.gender ? (
                  <div className="flex items-center mt-2 gap-2 capitalize">
                    <span className="font-medium min-w-[100px]"> Gender at time of birth :</span>{" "}
                    {patientInfoDetail?.gender}
                  </div>
                ) : null}
              </div>
              <div className="patient-details-container border p-3 rounded">
                <div className="flex flex-col flex-wrap items-center gap-2 justify-between">
                  <p className="text-2xl font-semibold text-current">
                    {patientLatestReading?.length ||
                      patientLatestReading?.count
                    }
                  </p>
                  <p>
                    {moment()
                      .month(
                        patientLatestReading && patientLatestReading.length
                          ? patientLatestReading[0]?.month - 1
                          : readingMonth - 1
                      )
                      .format("MMMM")}{" "}
                    Reading Count
                  </p>
                  <Button size="small" type="primary" onClick={() => setShowReadingModal(true)}>
                    Change
                  </Button>
                </div>
              </div>
            </div>

            <Row>
              <Col xs={24}>
                <div className="flex gap-2 flex-wrap pt-3 mt-4 border-t-2 border-current">
                  <Button onClick={handleUpdatePatientShow} disabled={!(userInfo?.userInfo?.grantType === "caretaker_all" ||
                    userInfo?.userInfo?.grantType === "caretaker_register_patient")}>Patient Info</Button>

                  {/* {(userInfo?.userInfo?.grantType === "caretaker_all" ||
                  (userInfo?.userInfo?.grantType === "caretaker_register_patient" &&
                    userInfo?.userInfo?.grantType === "caretaker_manual_log")) ? (
                    <Button onClick={handleAddManualListShow}>Insurance</Button>
                  ) : null} */}
                  {(userInfo?.userInfo?.grantType === "caretaker_all" ||
                    userInfo?.userInfo?.grantType === "caretaker_register_patient") ? (
                    <Button onClick={handleAddManualListShow} className="flex justify-center items-center">
                      Insurance
                      {menuConfigLoading ? null : !ptMenuConfig?.insurance &&
                        <ExclamationCircleFilled className="text-[#E10505]" />
                      }
                    </Button>
                  ) : null}
                  {patientInfoDetail?.userType === "outpatient" ? (
                    <Button className="flex justify-center items-center" onClick={handleUpdateAddressShow} disabled={!(userInfo?.userInfo?.grantType === "caretaker_all" ||
                      userInfo?.userInfo?.grantType === "caretaker_register_patient")}>
                      Address
                      {menuConfigLoading ? null : !ptMenuConfig?.address &&
                        <ExclamationCircleFilled className="text-[#E10505]" />
                      }
                    </Button>
                  ) : (
                    <Button className="flex justify-center items-center" onClick={handleUpdateFacilityShow} disabled={!(userInfo?.userInfo?.grantType === "caretaker_all" ||
                      userInfo?.userInfo?.grantType === "caretaker_register_patient")}>
                      Facility Address
                      {menuConfigLoading ? null : !ptMenuConfig?.address &&
                        <ExclamationCircleFilled className="text-[#E10505]" />
                      }
                    </Button>
                  )}
                  <Button className="flex justify-center items-center" onClick={handleUpdateVitalsShow} disabled={!(userInfo?.userInfo?.grantType === "caretaker_all" ||
                    userInfo?.userInfo?.grantType === "caretaker_register_patient")}>
                    Height & Weight
                    {menuConfigLoading ? null : !ptMenuConfig?.vital &&
                      <ExclamationCircleFilled className="text-[#E10505]" />
                    }
                  </Button>
                  <Button className="flex justify-center items-center" onClick={handleUpdateMonitoringShow}>
                    Diagnostics & Monitoring
                    {menuConfigLoading ? null : (!ptMenuConfig?.diagnostic || !ptMenuConfig?.monitoring) &&
                      <ExclamationCircleFilled className="text-[#E10505]" />
                    }
                  </Button>
                  <Button onClick={handleStatusUpdateShow} className="flex justify-center items-center">
                    Status
                  </Button>
                  {showGroupButton &&
                    <Button onClick={() => setShowAssignGroupModal(true)} className="flex justify-center items-center">
                      Groups
                    </Button>
                  }
                </div>
              </Col>
              <Col xs={24}>
                {/* {rpmDevices && rpmDevices.length ? (
                  <Alert type="error" className="mt-4" message={userStatusMessage()} />
                ) : null} */}
                {patientInfoDetail?.critical ? (
                  <Alert type="error" className="mt-4" message={getCriticalDevices()} />
                ) : null}
              </Col>
              {specialInstructions && (
                <Col span={24}>
                  <div className="mt-3">
                    <h4 className="text-xl font-medium">Special Instruction</h4>
                    <p className="mt-1">{specialInstructions?.specialInstructions}</p>
                    <hr className="my-2" />
                    <div
                      className="flex justify-between"
                      style={{ color: "rgba(102, 107, 123, 1)" }}>
                      <span>
                        Noted on:{" "}
                        {moment(specialInstructions?.lastModifiedDate).format(
                          "MM/DD/YYYY [at] hh A"
                        )}{" "} {specialInstructions?.timezone && `(${specialInstructions?.timezone})`}
                      </span>
                      <span className="capitalize">
                        Noted by: {specialInstructions?.lastModifiedName}
                      </span>
                    </div>
                  </div>
                </Col>
              )}
            </Row>
          </Card>
        </Col>
        <Col xs={24} lg={8}>
          <Card className="patient-details-container">
            <div className="flex gap-3 flex-wrap justify-between">
              {/* <div className="flex flex-col mb-2">
                <span className="font-medium min-w-[100px] block">Insurance: </span>
                {!patientInsurance || patientInsurance.length === 0 ? (
                  <span>No Insurance for this patient.</span>
                ) : (
                  <div className="mt-2">
                    {patientInsurance?.map((pro, index) => (
                      <div key={index}>
                        <div className="flex items-center">
                          <span className="min-w-[140px]">Insurance Provider:</span>
                          <span className="font-medium">{pro?.insuranceProvider}</span>
                        </div>
                        <div className="flex items-center mt-1">
                          <span className="min-w-[140px]">Insurance Number:</span>
                          <span className="font-medium">{pro?.insuranceNumber}</span>
                        </div>
                        <div className="flex items-center mt-1">
                          <span className="min-w-[140px]">Insurance Status:</span>
                          <span>
                            <Tag color={pro?.status ? "success" : "error"}>
                              {pro?.status ? "Active" : "Inactive"}
                            </Tag>
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div> */}
              <div className="w-auto flex gap-3 flex-wrap justify-between mt-2">
                <div className="flex flex-col mb-2">
                  <span className="font-medium min-w-[100px] block">Caretakers: </span>
                  {!patientCaretaker || Object.keys(patientCaretaker).length === 0 ? (
                    <span>No Caretaker selected for this patient.</span>
                  ) : (
                    <ol className="list-decimal ml-3 mt-2">
                      {patientCaretaker?.map((ct, index) => (
                        <li key={index}>
                          <span className="capitalize">{`${ct?.firstName} ${ct?.middleName ?? ""} ${ct?.lastName
                            }`}</span>
                        </li>
                      ))}
                    </ol>
                  )}
                </div>
                <div>
                  <span className="font-medium min-w-[100px] block">Group: </span>
                  <span className="capitalize">{patientGrpName && (patientGrpName.length !== 0) ? patientGrpName?.join(", ") : "No Group Assigned"}</span>
                </div>
              </div>
            </div>
            <div className="flex gap-3 flex-wrap justify-between mt-2">
              <div className="flex flex-col mb-2">
                <span className="font-medium min-w-[100px] block">Monitor Types: </span>
                {monitoringLoading ? (
                  <Spin></Spin>
                ) : (
                  <>
                    {!patientMonitoringData || Object.keys(patientMonitoringData).length === 0 ? (
                      <span>No Devices are selected for this patient.</span>
                    ) : (
                      <ol className="list-decimal ml-3 mt-2">
                        {Object.keys(patientMonitoringData)?.map((device, index) => {
                          return (
                            <li key={index}>
                              <span className="uppercase">{device}</span>
                            </li>
                          )
                        })}
                      </ol>
                    )}
                  </>
                )}
              </div>
              <div className="flex flex-col mb-2">
                <span className="font-medium">Providers: </span>
                {assignedProviders?.rpmProviderList?.length === 0 &&
                  assignedProviders?.ccmProviderList?.length === 0 &&
                  assignedProviders?.pcmProviderList?.length === 0 ? (
                  <span>No Providers are selected for this patient.</span>
                ) : (
                  <div className="capitalize">
                    {assignedProviders?.rpmProviderList?.length ? (
                      <ol className="list-decimal ml-3 mt-2">
                        <span className="font-medium">RPM : </span>
                        <span>{assignedProviders?.rpmProviderList?.join(", ")}</span>
                      </ol>
                    ) : null}
                    {assignedProviders?.ccmProviderList?.length ? (
                      <ol className="list-decimal ml-3 mt-2">
                        <span className="font-medium">CCM : </span>
                        <span>{assignedProviders?.ccmProviderList?.join(", ")}</span>
                      </ol>
                    ) : null}
                    {assignedProviders?.pcmProviderList?.length ? (
                      <ol className="list-decimal ml-3 mt-2">
                        <span className="font-medium">PCM : </span>
                        <span>{assignedProviders?.pcmProviderList?.join(", ")}</span>
                      </ol>
                    ) : null}
                  </div>
                )}
              </div>

              <div className="flex flex-col mb-2">
                <span className="font-medium">Practitioners: </span>
                {assignedPractitioners?.rpmPractitionerList?.length === 0 &&
                  assignedPractitioners?.ccmPractitionerList?.length === 0 &&
                  assignedPractitioners?.pcmPractitionerList?.length === 0 ? (
                  <span>No Practitioners are selected for this patient.</span>
                ) : (
                  <div className="capitalize">
                    {assignedPractitioners?.rpmPractitionerList?.length ? (
                      <ol className="list-decimal ml-3 mt-2">
                        <span className="font-medium">RPM : </span>
                        <span>{assignedPractitioners?.rpmPractitionerList?.join(", ")}</span>
                      </ol>
                    ) : null}
                    {assignedPractitioners?.ccmPractitionerList?.length ? (
                      <ol className="list-decimal ml-3 mt-2">
                        <span className="font-medium">CCM : </span>
                        <span>{assignedPractitioners?.ccmPractitionerList?.join(", ")}</span>
                      </ol>
                    ) : null}
                    {assignedPractitioners?.pcmPractitionerList?.length ? (
                      <ol className="list-decimal ml-3 mt-2">
                        <span className="font-medium">PCM : </span>
                        <span>{assignedPractitioners?.pcmPractitionerList?.join(", ")}</span>
                      </ol>
                    ) : null}
                  </div>
                )}
              </div>
            </div>
            <div className="flex flex-col my-2">
              <span className="font-medium min-w-[100px] block">ICD Codes: </span>
              {icdCodesLoading ? <Spin></Spin> :
                (<>{!patientDiagnosticData?.length ? (
                  <span>No ICD codes for this patient.</span>
                ) : (
                  <div>
                    {patientDiagnosticData.map((item, index) => (
                      <span key={index} className="font-normal uppercase">&nbsp;
                        {item.icdCode}{index === patientDiagnosticData?.length - 1 ? "" : ","} </span>
                    ))}
                  </div>
                )}</>)}
            </div>
            <div className="flex flex-col my-2">
              <span className="font-medium min-w-[100px] block">Status: </span>
              {patientStatusLoading ? <Spin></Spin> :
                  <div>
                    <p>
                    Equipment Status : {patientStatus?.equipmentStatus ?? "-"}{" "}
                    {patientStatus?.equipmentStatus?.toLowerCase()?.trim() === "in transit"
                      ? `(${
                          shippingtStatusLoading
                            ? "Loading..."
                            : `Shipment Id: ${shipmentStatus?.shipmentId}`
                        })`
                      : ""}
                      {patientStatus?.equipmentStatus?.toLowerCase()?.trim() === "delivered" || shipmentStatus?.date
                      ? `(${
                          shippingtStatusLoading
                            ? "Loading..."
                            : `Shipment Id: ${shipmentStatus?.shipmentId})   ${shipmentStatus?.date ? moment(shipmentStatus?.date).format("MM-DD-YYYY HH:mm A") : ""}`
                        }`
                      : ""}
                    </p>
                    <p>Patient Status : {patientStatus?.profileStatus ?? "-"}</p>
                    <p>Marketing Subscription Status :  {typeof(patientStatus?.marketingStatus) === "boolean" ? (patientStatus?.marketingStatus ? "Yes" : "No") : "-"}</p>
                    <p>Billable Status : {typeof(patientStatus?.billingStatus) === "boolean" ? (patientStatus?.billingStatus ? "Yes" : "No") : "-"}</p>

                </div>}
            </div>
            {
                patientAllergies?.length > 0 && (
                  <div className="flex flex-col my-2">
                    <div className="font-medium min-w-[100px] mb-2 block">Allergies:</div>
                    <div className="flex flex-wrap max-w-[250px] break-words">
                      {patientAllergies.map((allergy) => (
                        <p className="bg-gray-200 p-2 rounded-xl shadow-md mr-2 mb-2" key={allergy.id}>{allergy.name}</p>
                      ))}
                    </div>
                  </div>
                )
              }
          </Card>
        </Col>
        {/* {showWm || showBp || showOxi || showGluco || showPM || showMH || showAM ? ( */}
        {showWm || showBp || showOxi || showGluco ? (
          <Col xs={24}>
            <Card className="mt-3">
              <Row>
                <Col xs={24}>
                  <Tabs tabPosition="top" className="w-100 mt-4" items={items(patientInfoDetail)} onChange={onChange} />
                </Col>
              </Row>
            </Card>
          </Col>
        ) : null}
      </Row>
      <ReadingModal
        readingMonth={readingMonth}
        setReadingMonth={setReadingMonth}
        patientInfoDetail={patientInfoDetail}
        showReadingModal={showReadingModal}
        setShowReadingModal={setShowReadingModal}
        patientLatestReading={patientLatestReading}
        currentMonthReading={location?.state?.totalReading}
      />
      <UpdatePatientBasicDetails
        updatePatientShow={updatePatientShow}
        setUpdatePatientShow={setUpdatePatientShow}
        handleUpdatePatientClose={handleUpdatePatientClose}
        patientOptOut={patientOptOut}
      />
      <InsuranceListModal
        setSelectedInsurance={setSelectedInsurance}
        setInsuranceAction={setInsuranceAction}
        insuranceListApiCalls={insuranceListApiCalls}
        showPatientInsuranceList={showPatientInsuranceList}
        setShowUpdatePatientInsurance={setShowUpdatePatientInsurance}
        handleAddUpdateInsuranceListClose={handleAddUpdateInsuranceListClose}
      />
      <AddUpdateInsurance
        selectedInsurance={selectedInsurance}
        setSelectedInsurance={setSelectedInsurance}
        showUpdatePatientInsurance={showUpdatePatientInsurance}
        setShowUpdatePatientInsurance={setShowUpdatePatientInsurance}
        insuranceAction={insuranceAction}
      />
      <UpdateOutPatientAddress
        updateAddressShow={updateAddressShow}
        handleUpdateAddressClose={handleUpdateAddressClose}
      />
      {
        patientInfoDetail?.userType === "facility" &&
        <UpdatePatientFacility
          updateFacilityShow={updateFacilityShow}
          handleUpdateFacilityClose={handleUpdateFacilityClose}
        />
      }
      {/* <UpdatePatientDiagnostics
        patientId={patientId}
        updateDiagnosticsShow={updateDiagnosticsShow}
        handleUpdateDiagnosticsClose={handleUpdateDiagnosticsClose}
      /> */}
      <UpdatePatientVitals
        patientId={patientId}
        patientVitals={patientVitals}
        updateVitalsShow={updateVitalsShow}
        handleUpdateVitalsClose={handleUpdateVitalsClose}
      />
      <UpdateMonitoring
        patientId={patientId}
        updateMonitoringShow={updateMonitoringShow}
        patientMonitoringData={patientMonitoringData}
        setUpdateMonitoringShow={setUpdateMonitoringShow}
        handleUpdateMonitoringClose={handleUpdateMonitoringClose}
      />
      <UpdatePatientStatus
        patientId={patientId}
        // patientCaretaker={patientCaretaker}
        // caretakerLoading={caretakerLoading}
        statusUpdateShow={statusUpdateShow}
        handleStatusUpdateClose={handleStatusUpdateClose}
      />
      {showAssignGroupModal &&
        <AssignGroupToPatient
          patientId={patientId}
          assignableGroups={assignableGroups}
          showAssignGroupModal={showAssignGroupModal}
          setShowAssignGroupModal={setShowAssignGroupModal}
        />
      }
    </>
  )
}

export default PatientDetailInfo
