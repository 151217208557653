/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import React, { useEffect, useState } from "react"
import {
  Button,
  Checkbox,
  Divider,
  Dropdown,
  Input,
  Radio,
  Space,
  Spin,
  Typography,
  theme
} from "antd"
import { CloseOutlined, DownOutlined, LoadingOutlined, SearchOutlined } from "@ant-design/icons"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { debounce } from "lodash"
import { GetGroups } from "../../redux/actions/groupsActions"

const { useToken } = theme

const GroupedByDropdown = props => {

  const {
    onGroupSelection,
    onGroupSelectionClear
  } = props
  const { token } = useToken()
  const { isDark } = useSelector(state => state.themeConfig)

  const [open, setOpen] = useState(false)
  const [values, setValues] = useState()
  const [groupsNames, setGroupsNames] = useState([])
  const [filteredGroupList, setFilteredGroupList] = useState([])
  const [groupAssigned, setGroupAssigned] = useState([])
  const [selectedGroups, setSelectedGroups] = useState([])
  const [searchedQuery, setSearchQuery] = useState("")
  const { userInfo } = useSelector(state => state.userInfo)
  const { loading: groupsLoading, groups } = useSelector(state => state.getGroups)

  const dispatch = useDispatch()

  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary
    // width: "140%"
  }

  useEffect(() => {
    let groupNames = []
    selectedGroups.map(item => {
      groupNames.push(item?.label)
    })
    setGroupAssigned(groupNames)
    onGroupSelection(selectedGroups)
  }, [selectedGroups])

  useEffect(() => {
    if (!groups?.length && open) dispatch(GetGroups(userInfo?.id))
  }, [open])

  useEffect(() => {
    // @ts-expect-error
    if (groups) {
      // @ts-expect-error
      let care = groups?.map((item, index) => ({
        label: item?.name,
        value: item?.id,
        index: index + 1
      }))
      care = [...care, { label: "All", value: "All", index: 0 }]
      setFilteredGroupList(care)
      setGroupsNames(care)
    }
  }, [groupsLoading, open])

  const onOpenChange = open => {
    if (!open) {
      setValues(groupAssigned)
    }
    setOpen(open)
  }

  const onSearchCaretaker = e => {
    setSearchQuery(e?.target?.value)
  }

  useEffect(() => {
    let filterData = []

    filterData = groupsNames?.filter(group =>
      // @ts-expect-error
      group?.label?.toLowerCase().includes(searchedQuery?.toLowerCase())
    )
    setFilteredGroupList(filterData)
  }, [searchedQuery])

  const onSelectedGroup = checkedItem => {
    if (checkedItem.value === "All") {
      if (selectedGroups.some(item => item.value === "All")) {
        setSelectedGroups([]);
      } else {
        setSelectedGroups(filteredGroupList);
      }
    } else {
      const isSelected = selectedGroups.some(item => item.value === checkedItem.value);
      let updatedGroups;
      if (isSelected) {
        updatedGroups = selectedGroups.filter(item => item.value !== checkedItem.value);
      } else {
        updatedGroups = [...selectedGroups, checkedItem];
      }

      if (updatedGroups.some(item => item.value !== "All") && updatedGroups.length < filteredGroupList.length) {
        updatedGroups = updatedGroups.filter(item => item.value !== "All");
      }

      setSelectedGroups(updatedGroups);
    }
  };

  return (
    <>
      <Dropdown
        open={open}
        onOpenChange={onOpenChange}
        destroyPopupOnHide
        menu={{ items: [] }}
        trigger={["click"]}
        dropdownRender={_ => (
          <div style={contentStyle}>
            {
              <>
                {!groupsLoading ? (
                  <>
                    <Input
                      key="search"
                      className="mb-3 px-3"
                      style={{ width: "100%", height: 38 }}
                      onChange={debounce(onSearchCaretaker, 200)}
                      allowClear
                      // disabled={!tab2Data?.length}
                      placeholder="Search Group"
                      prefix={<SearchOutlined className="disable" />}
                    />

                    <div className="px-4 mr-8 h-44 overflow-auto">
                      <Checkbox.Group
                        className="flex-col w-full capitalize"
                        // @ts-expect-error
                        value={selectedGroups?.map(item => item?.value)}>
                        {filteredGroupList
                          ?.sort((a, b) => a?.index - b?.index)
                          .map(item => (
                            <Checkbox
                              // @ts-expect-error
                              key={item.value}
                              onChange={selectedValues => onSelectedGroup(item)}
                              // @ts-expect-error
                              value={item.value} // Use the item's value directly
                            >
                              {" "}
                              {/* @ts-expect-error */}
                              {item?.label}
                            </Checkbox>
                          ))}
                      </Checkbox.Group>
                    </div>
                  </>
                ) : (
                  <Spin className="flex justify-center items-center h-full" />
                )}
              </>
              // )
            }
            <Divider className="my-3" />

            {/* <div className="px-8 pb-4 flex justify-center">
              <Button type="primary" onClick={handleApply}>
                Done
              </Button>
            </div> */}
          </div>
        )}>
        <Button
          style={{
            // minWidth: 150,
            height: "inherit"
          }}>
          <div
            className={`${isDark ? "dark" : "light"}`}
            style={{ width: "auto", height: 38, display: "flex", placeItems: "center" }}>
            <span className="disable text-sm pr-3">Grouped By</span>
            <div
              className="primary border-l-2 h-full text-center flex-1"
              style={{
                display: "flex",
                placeItems: "center",
                borderColor: isDark ? "#424242" : "rgba(0, 0, 0, 0.1)"
              }}>
              <div className="flex-1">
                <span className="px-2 capitalize">{groupAssigned && groupAssigned?.length !== 0 ? (groupAssigned?.includes("All") ? "All" : `${groupAssigned?.length && groupAssigned?.[0]}${groupAssigned?.length === 1 ? "" : ","} ${groupAssigned?.length >= 2 ? groupAssigned?.length && groupAssigned?.[1] : ""}`) : ""}{" "}
                  {!groupAssigned.includes("All") &&
                    (selectedGroups?.length !== 0) && (selectedGroups?.length > 2) && (
                      <span className="bg-[#d7a66d] text-[#fff] rounded-2xl p-[0.2rem] mx-1">
                        +{selectedGroups?.length - 2}
                      </span>
                    )}</span>
                <DownOutlined />
              </div>
            </div>
          </div>
        </Button>
      </Dropdown>
      {/* <Space.Compact
        className={`border card ${isDark ? "dark" : "light"} `}
        onClick={handleResetFilter}
        style={{ borderWidth: 1 }}>
        <span className="secondary px-4 pt-2">
          <CloseOutlined />
        </span>
      </Space.Compact> */}
    </>
  )
}

export default GroupedByDropdown

